import React,{useState} from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
function Footer() {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    
    if (Object.keys(errors).length === 0) {
      APISerive.Insert_subscribe({
        email: email,
      }).then((res) => {  
        setEmail("");        
        toast.success("You are subscribed  !", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      // Form validation failed, display error messages
  
      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };
  return (
    <div>
      {/*/Top Paid Apps*/}
      {/*Footer Section*/}
      <section>
        <footer className="bg-dark text-white">
          <div className="footer-main border-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-12 footer-about">
                  <h6>About</h6>
                  <p className="mb-1">
                  AIConsumer isn't just a website; it's a community where creativity knows no bounds. Embrace the power of collaboration, stay informed, share your insights, and let your ideas soar
                  </p>
                  
                </div>
                <div className="col-lg-3 col-md-12">
                  <h6>Contact</h6>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="google.com;">
                        <i className="fa fa-home me-3 text-primary" /> New York,
                        NY 10012, US
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="fa fa-envelope me-3 text-primary" />{" "}
                        info12323@example.com
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="fa fa-phone me-3 text-primary" /> + 01 234
                        567 88
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="fa fa-print me-3 text-primary" /> + 01 234
                        567 89
                      </a>
                    </li>
                  </ul>
                  <ul className="list-unstyled list-inline mt-2">
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight waves-effect waves-light">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight waves-effect waves-light">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight waves-effect waves-light">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight waves-effect waves-light">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12">
                  <h6>Apps Store</h6>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <a href="google.com;">
                        <i className="typcn typcn-chevron-right-outline me-1" />
                        Download Center
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="typcn typcn-chevron-right-outline me-1" />
                        Order tracking
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="typcn typcn-chevron-right-outline me-1" />
                        Returns
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="typcn typcn-chevron-right-outline me-1" />
                        Downloads
                      </a>
                    </li>
                    <li>
                      <a href="google.com;">
                        <i className="typcn typcn-chevron-right-outline me-1" />
                        Reviews
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-12">
                  <h6>Subscribe</h6>
                  <form onSubmit={handleSubmit} method="POST">
                    <div className="input-group ">
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              placeholder="Email Address"
                      />
                      <button type="submit" className="btn btn-primary">
                        Subscribe
                      </button>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
          <div className="bg-dark text-white p-0 border-bottom">
            <div className="container">
              <div className="p-2 text-center footer-links">
                
                <Link to="/aboutus" className="btn btn-link">
                  About Us
                </Link>
                <a href="/contact-us" className="btn btn-link">
                  Contact Us
                </a>
                <a href="/blogs" className="btn btn-link">
                  Blogs
                </a>
                <a href="/products" className="btn btn-link">
                  Applications
                </a>
                <a href="/register" className="btn btn-link">
                  Registration
                </a>
                <a href="/login" className="btn btn-link">
                  Login
                </a>
              </div>
            </div>
          </div>
          <div className="bg-dark primary-gradient text-white p-1">
            <div className="container">
              <div className="row d-flex">
                <div className="col-lg-12 col-sm-12  mt-2 mb-2 text-center ">
                  Copyright © 2023{" "}
                  <a href="google.com;" className="fs-14 text-primary">
                    The AI Consumer
                  </a>
                  All rights reserved.
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
      {/*Footer Section*/}
      {/* Back to top */}
      <a href="#top" id="back-to-top">
        <i className="fa fa-rocket" />
      </a>
    </div>
  );
}

export default Footer;
