import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import DOMPurify from "dompurify";
import "./Mymodel.css";
import config from "../../config";
function sanitizeAndRenderHTML(htmlContent) {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  return { __html: cleanHTML };
}

const MyModalApp = ({ show, onHide, id }) => {
  const [apps, setApps] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_token = localStorage.getItem("token");
        // Fetch article data
        const apps = await APISerive.getApp(id);
        console.log(apps);

        setApps(apps);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);
  return (
    <div
      className={`modal fade bd-example-modal-lg modalBackground ${
        show ? "show" : ""
      }`}
      style={{ display: show ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content modalContainer">
          <div className="modal-header">
            <h5 className="modal-title">App Preview</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className=" body">
            <div className="card ">
              <div className="card-body">
                {apps ? ( // Check if article is not null or undefined
                  <>
                  <div className="card overflow-hidden">
                  <div className="d-md-flex">
                    <div className="item-card9-img text-center mt-0 ms-3">
                      <div className="item-card9-imgs">
                        <a href="app-details.html" />
                        <img
                          src={`${config.apiUrl}${apps.image_url}`}
                          alt="img"
                          className="cover-image"
                        />
                      </div>
                    </div>
                    <div className="card border-0 mb-0">
                      <div className="card-body pt-0 pt-md-5">
                        <div className="row">
                          <div className="col-lg-8 col-xl-9 col-md-12">
                            <div className="item-card9">
                              <a
                                href="paid-app-details.html"
                                className="text-dark d-flex"
                              >
                                <h4 className="font-weight-semibold mt-1 text-primary">
                                  {apps.title}
                                </h4>
                                <span className="text-muted mt-1 ms-1">
                                  4.56v
                                </span>
                              </a>
                              <a
                                href="#"
                                className="  font-weight-semibold text-green fs-14 d-flex"
                              >
                                <i />
                                ADD TO WISHLIST
                                <span className="border-start  text-muted ms-3">
                                  <i className="fa fa-apple text-muted ms-3 me-2" />
                                </span>
                              </a>
                              <p className="mb-0 leading-tight mt-3 mb-0">
                                {apps.description}
                              </p>
                            </div>
                            <div>
                              <div className=" d-flex">
                                <div className="itemcard">
                                  <ul className="item-cards software-list mb-0 mt-3">
                                    <li>
                                      <a
                                        href="google.com"
                                        className="icons"
                                      >
                                        <span className="text-black font-weight-semibold me-2 d-inline-block">
                                          File size :
                                        </span>
                                        <span className="text-muted">
                                          <i className="fa fa-database text-muted mx-1" />{" "}
                                          {apps.size}
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="google.com"
                                        className="icons"
                                      >
                                        <span className="text-black font-weight-semibold">
                                          License :
                                        </span>
                                        <span className="text-muted ms-1 d-inline-block">
                                          {apps.license}
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-xl-3 col-md-12">
                            <div className="download-sec  mt-3">
                              <span className="text-muted ">
                                {new Date(
                                  apps.inserted_datetime
                                ).toLocaleDateString()}
                              </span>
                              <div className="ms-auto mb-2 mt-2">
                                <div className="d-inline-flex flex-wrap">
                                  <div
                                    className="rating-star sm my-rating-5"
                                    data-stars="4s"
                                  ></div>{" "}
                                  (4.9/5)
                                </div>
                              </div>
                              <div className="item-card9-cost">
                                <a
                                  href={apps.applink}
                                  className="font-weight-bold fs-14 btn btn-green btn-block "
                                >
                                  click to go {`>>`}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                  </>
                ) : (
                  // Render a message or component when article is null or undefined
                  <p>No App found.</p>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onHide}
            >
              Close
            </button>
            {/*   <button type="button" className="btn btn-primary">
              Save changes
  </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyModalApp;
