import React from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import BlogList from "../component/blogList/blogList";
import NewsLetter from "../component/newsLetter/newsLetter";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import APISerive from "../component/Data/APIService";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import $ from "jquery";
import { Link } from "react-router-dom";

function Blog() {
  const dataTosend = { title: "Blog" };
  
  const [articles, setArticles] = useState([]);
  const [nextUrl,setnextUrl]=useState();
  const [previousUrl,setpreviousUrl]=useState();
  const [recArticles, setRecArticles] = useState([]);
  const [category, setcategory] = useState([""]);
  let navigate = useNavigate();


  const reloadBlogs=(newArticles)=>{
    console.log("data passed ")
    console.log(newArticles);
    setArticles(newArticles);
 
  }

  const reloadNextUrl=(newurl)=>{
    setnextUrl(newurl);

  }

  const reloadPreviousUrl=(newurl)=>{
    setpreviousUrl(newurl);

  }

  useEffect(() => {
    document.title = 'AIConsumer.Blogs';
  

    var user_token = localStorage.getItem("token");
    console.log("Login User token is from test", user_token);
    // calling post list functions
      
    APISerive.Articles_published().then((resp) => {
      setArticles(resp.results);
      setnextUrl(resp.next)
      setpreviousUrl(resp.previous)
    });

    // calling category function for No of post to each caetgory

    APISerive.BlogCategories().then((reponseData) => {
      setcategory(reponseData);
    });

    // recent posts
    APISerive.Articles_recent().then((resp) => {
      setRecArticles(resp);
    });

    $("#global-loader").fadeOut("slow");

  }, []);

  return (
    <div>
      <Header />
      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>Blogs</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Blogs List
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogList
        articles={articles}
        category={category}
        recArticles={recArticles}
        reloadBlogs={reloadBlogs}
        reloadNextUrl={reloadNextUrl}
        reloadPreviousUrl={reloadPreviousUrl}
        previousUrl={previousUrl}
        nextUrl={nextUrl}
      />
      <Footer />
    </div>
  );
}

export default Blog;
