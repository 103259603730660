import React, { useEffect } from "react";
import $ from "jquery";
import Footer from "../component/footer/footer";
import Header from "../component/header/Header";
import { Link } from "react-router-dom";

function About() {
  useEffect(() => {
    document.title = 'AIConsumer.about';
    $("#global-loader").fadeOut("slow");
  }, []);
  return (
    <div>
      <Header />
      {/* <Breadcrumb data={dataTosend} /> */}

      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>About Us</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    About Us
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="sptb ">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-xl-4  col-md-12  d-block mb-7">
              <div className="section-title center-block text-start">
                <h2>Contact Info</h2>
              </div>
              <div className="row text-white">
                <div className="col-12 mb-5">
                  <div className="support-service bg-primary mb-4 mb-xl-0">
                    <i className="fa fa-phone" />
                    <h6>+68 872-627-9735</h6>
                    <p>Free Support!</p>
                  </div>
                </div>
                <div className="col-12 mb-5">
                  <div className="support-service bg-secondary mb-4 mb-xl-0">
                    <i className="fa fa-clock-o" />
                    <h6>Mon-Sat(10:00-19:00)</h6>
                    <p>Working Hours!</p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="support-service bg-warning">
                    <i className="fa fa-envelope-o" />
                    <h6>yourdomain@gmail.com</h6>
                    <p>Support us!</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-8 col-md-12 d-block ">
              <div className="single-page">
                <div className="col-lg-12  col-md-12 mx-auto d-block">
                  <div className="section-title center-block text-start">
                    <figure class="text-center">
                      <blockquote class="blockquote">
                        <p>
                        Be With us and Join our Community
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                      you can have for what you are searching{" "}
                      <cite title="Source Title">The AI Consumer</cite>
                      </figcaption>
                    </figure>
                  </div>
                  <p>
                    Welcome to the heart of innovation at AIConsumer! Our
                    website isn't just a platform; it's a dynamic community
                    where technology enthusiasts, entrepreneurs, and innovators
                    converge. Here, you're not just a user; you're a valued
                    contributor to a collective of brilliant minds. Dive into a
                    world where cutting-edge technology meets creative
                    intellect.
                  </p>
                  <p>
                    Our website offers a plethora of resources tailored to your
                    interests. Are you curious about the latest trends in
                    artificial intelligence? Eager to explore the ever-evolving
                    landscape of consumer technology? You've come to the right
                    place. Our expertly curated blogs provide deep insights,
                    keeping you informed and inspired. But AIConsumer is more
                    than just a repository of knowledge. It's a platform for
                    your voice to be heard. We encourage you to share your
                    expertise and unique perspectives through our user-generated
                    blogs. Whether you're an industry expert, a passionate
                    hobbyist, or an aspiring entrepreneur, your ideas matter
                    here
                  </p>
                  <p>
                    Looking for opportunities in the tech world? Our website
                    provides comprehensive application details, ensuring you're
                    always in the loop about the latest programs, contests, and
                    job openings. And for those ready to take the plunge, we
                    offer the space to upload your applications, letting your
                    innovations shine.
                  </p>
                  <p>
                    Join us in shaping the future. AIConsumer isn't just a
                    website; it's a community where creativity knows no bounds.
                    Embrace the power of collaboration, stay informed, share
                    your insights, and let your ideas soar. Together, we're not
                    just consumers; we're architects of the future. Welcome to
                    AIConsumer, where innovation begins!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
