import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";

function AppUploadfrm() {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setcategory] = useState([""]);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    posandcosn: "",
    license: "",
    size: "",
    applink:""
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };
  const handleLicenseChange = (event) => {
    setFormData({ ...formData, license: event.target.value });
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    console.log(e.target.value);
  };

  useEffect(() => {
    APISerive.AppsCategories(localStorage.getItem("token")).then(
      (reponseData) => {
        setcategory(reponseData);
      }
    );
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      const formDataToSend = new FormData();

      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      formDataToSend.append("category", selectedCategory);
      formDataToSend.append("user", localStorage.getItem("user"));
      console.log(formDataToSend);
      try {
        APISerive.insert_apps(
          formDataToSend,
          localStorage.getItem("token")
        ).then((res) => {
          toast.success("Application submitted successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setFormData({
            title: "",
            description: "",
            posandcosn: "",
            license: "",
            size: "",
            applink:""
          });
          setSelectedCategory("");
        });
      } catch (error) {
        console.error("An error occurred:", error);
      }
    } else {

      setErrors(newErrors);

      // Display each error message in a toast
      Object.values(newErrors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!selectedCategory) {
      newErrors.category = "Category is required";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }

    if (!formData.posandcosn.trim()) {
      newErrors.posandcosn = "Pros and Cons is required";
    }

    if (!formData.license) {
      newErrors.license = "License is required";
    }

    if (!formData.image) {
      newErrors.image = "Image is required";
    }

    if (!formData.size) {
      newErrors.size = "File Size is required";
    }
    if (!formData.applink) {
      newErrors.applink = "App Link is required";
    }

    return newErrors;
  };

  return (
    <section className="sptb">
      <div className="container">
        <div className="row ">
          <div className="col-lg-8 col-md-12 col-md-12">
            <div className="card ">
              <div className="card-header ">
                <h3 className="card-title">ADD NEW APPLICATION</h3>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label className="form-label fw-semibold text-dark">
                      Ad Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="form-group mb-5">
                    <label className="form-label text-dark fw-semibold">
                      Select Categories
                    </label>
                    <select
                      className="form-control"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                    >
                      <option value="">Select an option</option>
                      {category.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label className="form-label fw-semibold text-dark">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      rows={6}
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      placeholder="Text here.."
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label fw-semibold text-dark">
                      Pros and Cons
                    </label>
                    <textarea
                      className="form-control"
                      name="posandcosn"
                      value={formData.posandcosn}
                      onChange={handleInputChange}
                      rows={6}
                      placeholder="define pros and cons.."
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label fw-semibold">Licence</label>
                    <div className="custom-controls-stacked d-md-flex">
                      <label className="custom-control custom-radio me-4">
                        <input
                          type="radio"
                          className="custom-control-input"
                          value="paid"
                          checked={formData.license === "paid"}
                          onChange={handleLicenseChange}
                          defaultChecked
                        />
                        <span className="custom-control-label">Free</span>
                      </label>
                      <label className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          value="free"
                          checked={formData.license === "free"}
                          onChange={handleLicenseChange}
                        />
                        <span className="custom-control-label">Paid</span>
                      </label>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                      Upload Images
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      id="image"
                      name="image"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12">
                      <div className="form-group mb-0">
                        <label className="form-label">File Size in (Mb)</label>

                        <input
                          type="text"
                          className="form-control"
                          name="size"
                          value={formData.size}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group mb-0">
                      <label className="form-label">App Link Address</label>

                      <input
                        type="text"
                        className="form-control"
                        name="applink"
                        value={formData.applink}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                </div>

                <div className="card-footer ">
                  <button type="submit" className="btn btn-primary">
                    Save App
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Terms And Conditions</h3>
              </div>
              <div className="card-body">
                <ul className="list-unstyled widget-spec  mb-0">
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Money Not Refundable
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    You can renew your Premium ad after experted.
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are active for depend on package.
                  </li>
                  <li className="ms-5 mb-0">
                    <a href="tips.html"> View more..</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Benefits Of Premium Ad</h3>
              </div>
              <div className="card-body pb-2">
                <ul className="list-unstyled widget-spec vertical-scroll mb-0">
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium Ads Active
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are displayed on top
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads will be Show in Google results
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium Ads Active
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are displayed on top
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads will be Show in Google results
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium Ads Active
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are displayed on top
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads will be Show in Google results
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium Ads Active
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are displayed on top
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads will be Show in Google results
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium Ads Active
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads are displayed on top
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />
                    Premium ads will be Show in Google results
                  </li>
                </ul>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Safety Tips For Buyers</h3>
              </div>
              <div className="card-body">
                <ul className="list-unstyled widget-spec  mb-0">
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />{" "}
                    Meet Seller at public Place
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />{" "}
                    Check item before you buy
                  </li>
                  <li>
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    />{" "}
                    Pay only after collecting item
                  </li>
                  <li className="ms-5 mb-0">
                    <a href="tips.html"> View more..</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppUploadfrm;
