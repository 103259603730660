import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import config from "../../config";
import { Link } from "react-router-dom";
import $ from 'jquery';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import StarRating from "../starRating/Rating";
function Slider() {
  const [apps, setApps] = useState([]);


  useEffect(() => {

     //var owl = $("top-visited-owl");
    // owl.OwlCarousel();
    const fetchData = async () => {
      try {
        $("#global-loader").fadeIn("slow");
        const resp = await APISerive.Apps_mostviewd_ByCategory();
        console.log("apps:", resp);
        setApps(resp);
      } catch (error) {
        console.error("Error fetching apps:", error);
      } finally {
        $("#global-loader").fadeOut("slow");
      }
    };

    fetchData();

    const owl = document.getElementById('owl');
    if (owl) {
      window.$(owl).owlCarousel({
        loop: true,
        rewind: false,
        margin: 25,
        // ... other options
      });
    }

    console.log("My Apps:",apps)

  }, []);

  const options = {
    loop: true,
    rewind: false,
    margin: 25,
    animateIn: 'fadeInDown',
    animateOut: 'fadeOutDown',
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: false,
    nav: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 2,
        nav: true
      },
      1300: {
        items: 4,
        nav: true
      }
    }
  };

  return (
    <div>
      {/*New Projects*/}
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>Top Visited Applications</h2>
            <p>
              Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
              vehicula
            </p>
          </div>
        { apps && apps && apps.length > 0 ?(
          <OwlCarousel id="owl" className="owl-theme" {...options}>
            {apps?.map((item, index) => (
              <div key={index} className="item">
                <div className="card mb-0 overflow-hidden">
                <div class="ribbon ribbon-top-left text-danger"><span class={item.category_name === ('Android'||'Mac') ? 'bg-danger' : 'bg-success'}>{item.category_name}</span></div>
                  <div className="item-card7-imgs">
                    <a href="app-details.html" />
                    <img
                      src={`${config.apiUrl}${item.image}`}
                      alt="Dash"
                      className="p-0"
                    />
                  </div>
                  <div className="card-body">
                    <div className="item-card7-desc">
                      <div className="item-card7-text">
                        <a
                          href="app-details.html"
                          className="text-dark text-header"
                        >
                          <h4 className="mb-1">{item.title}</h4>
                        </a>
                        <div className="d-inline-flex">
                          <div className=" d-inline-flex">
                            <div
                              className="rating-star sm my-rating-5"
                              data-stars="4s"
                            ></div>{" "}
                            <StarRating value={item.average_rating} />
                            ({item.average_rating}/5)
                          </div>
                        </div>
                      </div>
                      <ul className="item-cards7-ic software-list mb-0 mt-3">
                        <li>
                          <a href="google.com" className="icons text-muted ">
                            <i className="fa fa-database text-muted me-1" />{" "}
                            {item.size}
                            MB
                          </a>
                        </li>
                        <li>
                          <a href="google.com;" className="icons text-muted ">
                            <i className="fa fa-low-vision text-muted me-1" />
                            {item.visit_count}
                          </a>
                        </li>
                        <li>
                          <a href="google.com;" className="icons text-muted ">
                            <i className="fa fa-refresh text-muted me-1" />{" "}
                            20.0.4 v{" "}
                          </a>
                        </li>
                        <li>
                          <a href="google.com;" className="icons text-muted ">
                            <i className="fa fa-comment-o text-muted me-1" />{" "}
                            {item.comments_count} &nbsp;
                            Reviews
                          </a>
                        </li>
                      </ul>
                      <p className="mb-0">
                      {item.description.slice(0, 150)} <strong>...</strong> 
                      </p>
                      <Link to={`/products/App-detail/${item.id}`} className="btn btn-primary mt-3">
                        Visit
                      </Link>
                    </div>
                  </div>
                  <div className="card-footer pt-3 pb-3">
                    <div className="footerimg d-flex mt-0 mb-0">
                      <div className="d-flex footerimg-l mb-0 align-items-center">
                        <i className="fa fa-calendar me-2" />
                        {new Date(item.inserted_datetime).toLocaleDateString()}
                      </div>
                      <div className="footerimg-r ms-auto">
                        <a href="google.com;" title="Categorie">
                          {item.category_name}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
          ): (
            <p>Loading...</p>
          )}
          {/*  //this is */}
        </div>
      </section>
      {/*/New Projects*/}
    </div>
  );
}

export default Slider;
