import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import APIService from "../Data/APIService";
import { toast } from "react-toastify";

function Register() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useCookies(["mytoken"]);
  let navigate = useNavigate();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const RegisterBtn = (e) => {
    e.preventDefault();
    const errors = {};

    if (username.trim().length === 0) {
      errors.username = "Username is required";
    }

    if (password.trim().length === 0) {
      errors.password = "Password is required";
    }
    if (email.trim().length === 0) {
      errors.password = "Email is required";
    } else if (!isValidEmail(email)) {
      errors.password = "Invalid email address";
    }
    if (Object.keys(errors).length > 0) {
      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      return;
    }

  

    if (username.trim().length !== 0 && password.trim().length !== 0) {
      console.log("Username and password are set");
      APIService.RegisterUser({
        username: username,
        password: password,
        email: email,
      })
        .then((resp) => {
          setEmail("");
          setPassword("");
          setUsername("");
          toast.success("Success : Successfully registerd user, Please Login!", {
            position: toast.POSITION.TOP_CENTER,
          });
          //navigate("/")
        })
        .catch((error) => {
          // toast(error.message)
          console.log(error);
        });
    } else {
      navigate("/");
      console.log("Username and password are not set");
    }
  };
  return (
    <section className="sptb">
      <div className="container customerpage">
        <div className="row">
          <div className="single-page">
            <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
              <div className="wrapper wrapper2">
                <form id="Register" className="card-body" tabIndex={500}>
                  <h3>Register</h3>
                  <div className="name">
                    <input
                      type="text"
                      value={username}
                      className="form-control"
                      placeholder="Enter Username"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label>User Name</label>
                  </div>
                  <div className="passwd">
                    <input
                      type="password"
                      value={password}
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label>Password</label>
                  </div>
                  <div className="passwd">
                    <input
                      type="email"
                      value={email}
                      className="form-control"
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email</label>
                  </div>
                  <div className="submit">
                    <button
                      onClick={RegisterBtn}
                      className="btn btn-primary btn-block"
                      href="index.html"
                    >
                      Register
                    </button>
                  </div>
                  <p className="text-dark mb-0">
                    Already have an account?
                    <Link
                      to="/login"
                      className="text-primary ms-1 d-inline-block"
                    >
                      Sign In
                    </Link>
                  </p>
                </form>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Register;
