import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import APISerive from "../Data/APIService";
import StarRating from "../starRating/Rating";
import { Link, useFetcher } from "react-router-dom";
import $ from 'jquery'
function AppsList(props) {

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [appsudated, setAppsudated] = useState([]);
  const [pageUrl, setpageUrl] = useState();
  const [rating, setRating] = useState(3);
  const [selectedListValue, setSelectedListValue] = useState(1);
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  }; 
  
  const paginationSetUrl = (url) => {
    setpageUrl(url);
  };

  const paginationHandler = async (url) => {
    try {
      $("#global-loader").fadeIn("slow");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.results) {
        props.reloadNextUrl(data.next);
        props.reloadPreviousUrl(data.previous);
        console.log("datafrom paginaf")
        console.log(data.results)
        props.reloadApps(data.results);
      } else {
        console.error("Invalid response format:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{ $("#global-loader").fadeOut("slow")}
  };

  const handleCheckboxChange = (event, categoryId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    }
  };
  
 
  const handleSelectChange = (e) => {
    const selectedCategoryValue = e.target.value;
    console.log("Selected Category:", selectedCategoryValue);
    setSelectedListValue(selectedCategoryValue);
    
  };
  

  const fetchAppsByCategories = async () => {
    try {
      $("#global-loader").fadeIn("slow");
      
      if (selectedCategories.length === 0) {
        APISerive.Apps_published().then((resp) => {
          //setAppsudated(resp);
          props.reloadApps(resp.results);
          console.log("no select categro")
        }); 

        console.log("no select categro")
      } else {
        
        
        APISerive.Apps_published_byCategories(selectedCategories,selectedListValue).then(
          (resp) => {
            //setAppsudated(resp);
            props.reloadApps(resp.results);
          
          }
        ); 

      }
    } catch (error) {
      console.error("Error fetching apps:", error);
    } finally{$("#global-loader").fadeOut("slow");}
  };

  useEffect(() => {

   fetchAppsByCategories();  
  }, [selectedCategories,selectedListValue]); 

  useEffect(() => {
    paginationHandler(pageUrl);
  }, [pageUrl]);

 

  return (
    <div>
      <section className="sptb">
        <div className="container">
       {visible && (
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          {props.message}
          <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
        </div>
      )}
      
          <div className="row">
            <div className="col-xl-9 col-lg-8 col-md-12">
              {/*Add lists*/}
              <div className=" mb-lg-0">
                <div>
                  <div className="item2-gl ">
                    <div className="item2-gl-nav d-md-flex">
                      <h6 className="mb-0 mt-2">
                        Showing {props.apps.length} entries
                      </h6>
                      <ul className="nav item2-gl-menu ms-auto">
                        <li>
                          <a
                            href="#tab-11"
                            className="active show"
                            data-bs-toggle="tab"
                            title="List style"
                          >
                            <i className="fa fa-list" />
                          </a>
                        </li>
                        <li>
                          <a href="#tab-12" data-bs-toggle="tab" title="Grid">
                            <i className="fa fa-th" />
                          </a>
                        </li>
                      </ul>
                      <div className="d-flex">
                        <label className="me-2 mt-1 mb-sm-1">Sort By:</label>
                        <select
                          name="item"
                          className="form-control w-70"
                          value={selectedListValue}
                          onChange={handleSelectChange}
                        >
                          <option value={1}>All </option>
                          <option value={2}>Mostly Visited </option>
                          <option value={3}>Last 10 Days Uploaded</option>
                        </select>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab-11">
                        {props.apps && Object.values(props.apps).map((app) => {
                          return (
                            // return appsGroup.map((app) => (
                            <div className="card overflow-hidden">
                              <div className="d-md-flex">
                                <div className="item-card9-img text-center mt-0 ms-3">
                                  <div className="item-card9-imgs">
                                    <a href="app-details.html" />
                                    <img
                                      src={`${config.apiUrl}${app.image_url}`}
                                      alt="img"
                                      className="cover-image"
                                    />
                                  </div>
                                </div>
                                <div className="card border-0 mb-0">
                                  <div className="card-body pt-0 pt-md-5">
                                    <div className="row">
                                      <div className="col-lg-8 col-xl-9 col-md-12">
                                        <div className="item-card9">
                                          <Link
                                            to={`/products/App-detail/${app.id}`}
                                            className="text-dark d-flex"
                                          >
                                            <h4 className="font-weight-semibold mt-1 text-primary">
                                              {app.title}
                                            </h4>
                                            <span className="text-muted mt-1 ms-1">
                                              4.56v
                                            </span>
                                          </Link>
                                          <a
                                            href="#"
                                            className="  font-weight-semibold text-green fs-14 d-flex"
                                          >
                                            <i />
                                            ADD TO WISHLIST
                                            <span className="border-start  text-muted ms-3">
                                              <i className="fa fa-apple text-muted ms-3 me-2" />
                                            </span>
                                          </a>
                                          <p className="mb-0 leading-tight mt-3 mb-0">
                                            {app.description}
                                          </p>
                                        </div>
                                        <div>
                                          <div className=" d-flex">
                                            <div className="itemcard">
                                              <ul className="item-cards software-list mb-0 mt-3">
                                                <li>
                                                  <a
                                                    href="google.com"
                                                    className="icons"
                                                  >
                                                    <span className="text-black font-weight-semibold me-2 d-inline-block">
                                                      File size :
                                                    </span>
                                                    <span className="text-muted">
                                                      <i className="fa fa-database text-muted mx-1" />{" "}
                                                      {app.size} MB
                                                    </span>
                                                  </a>
                                                </li>
                                                <li>
                                                  <a
                                                    href="google.com"
                                                    className="icons"
                                                  >
                                                    <span className="text-black font-weight-semibold">
                                                      License :
                                                    </span>
                                                    <span className="text-muted ms-1 d-inline-block">
                                                      {app.license}
                                                    </span>
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-4 col-xl-3 col-md-12">
                                        <div className="download-sec  mt-3">
                                          <span className="text-muted ">
                                            {new Date(
                                              app.inserted_datetime
                                            ).toLocaleDateString()}
                                          </span>
                                          <div className="ms-auto mb-2 mt-2">
                                            <div className="d-inline-flex flex-wrap justify-content-center align-items-center" style={{textAlign:'center',verticalAlign:'middle'}}>
                                              
                                              <StarRating value={app.average_rating} />
                                             <p style={{marginTop:'20px;'}}> ({app.average_rating}/5) </p>
                                            </div>
                                          </div>
                                          <div className="item-card9-cost">
                                            <Link
                                              to={`/products/App-detail/${app.id}`}
                                              className="font-weight-bold fs-14 btn btn-green btn-block "
                                            >
                                              click to go {`>>`}
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // ));
                          );
                        })}
                      </div>
                      <div className="tab-pane" id="tab-12">
                        <div className="row">
                        {props.apps && Object.values(props.apps).map((app) => {
                            return (
                              <div className="col-lg-6 col-md-12 col-xl-4">
                                <div className="card overflow-hidden text-center">
                                  <div className="ribbon ribbon-top-left text-danger">
                                    <span className="bg-orange">New</span>
                                  </div>
                                  <div className="item-card9-img">
                                    <div className="item-card9-imgs">
                                      <a href="app-details.html" />
                                      <img
                                        src={`${config.apiUrl}${app.image_url}`}
                                        alt="img"
                                        className="cover-image"
                                      />
                                    </div>
                                  </div>
                                  <div className="card-body">
                                    <div className="item-card9">
                                      <a
                                        href="app-details.html"
                                        className="text-dark"
                                      >
                                        <h4 className="font-weight-semibold mt-1 mb-0 text-primary d-inline-flex">
                                          {app.title}
                                        </h4>
                                        <span className="text-muted ms-1">
                                          4.56v
                                        </span>
                                      </a>
                                      <br />
                                      <a
                                        href="#"
                                        className="text-green font-weight-semibold mt-1 mb-1"
                                      >
                                        ADD TO WISHLIST{" "}
                                      </a>
                                      <br />
                                      <span>
                                        <i className="fa fa-apple text-muted me-2" />
                                      </span>
                                      <p className="mt-3">{app.description.slice(0, 150)} <strong>...</strong>  </p>
                                      <div className="item-card9-desc">
                                        <a href="google.com" className="icons">
                                          <span className="text-black font-weight-semibold me-2 d-inline-block">
                                            File size :
                                          </span>
                                          <span className="text-muted">
                                            <i className="fa fa-database text-muted mx-1" />{" "}
                                            {app.size} MB
                                          </span>
                                        </a>

                                        <a href="google.com" className="icons">
                                          <span className="text-black font-weight-semibold">
                                            License :
                                          </span>
                                          <span className="text-muted me-2 d-inline-block">
                                            <i className=" text-muted  mx-1 text-muted" />{" "}
                                            {app.license}
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="download-sec">
                                    <div className="ms-auto mb-2">
                                      <div className="d-inline-flex flex-wrap">
                                        <div
                                          className="rating-star sm my-rating-5"
                                          data-stars="4s"
                                        ></div>{" "}
                                        <StarRating value={app.average_rating} />
                                        <p style={{marginTop:'20px;'}}> ({app.average_rating}/5) </p>
                                      </div>
                                    </div>
                                    <div className="item-card9-cost mb-5">
                                      <a
                                        href={app.applink}
                                        className="btn btn-green  fs-14"
                                      >
                                        click to go {`>>`}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="center-block text-center">
                  <ul className="pagination mb-5 mb-lg-0">
                    {props.previousUrl && (
                      <li className="page-item page-prev ">
                        <button
                          className="page-link"
                          onClick={() => paginationSetUrl(props.previousUrl)}
                          tabIndex={-1}
                        >
                          prev
                        </button>
                      </li>
                    )}
                    {props.nextUrl && (
                      <li className="page-item page-next">
                        <button
                          className="page-link"
                          onClick={() => paginationSetUrl(props.nextUrl)}
                        >
                          Next
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
                </div>
              </div>
              {/*/Add lists*/}
            </div>
            {/*Right Side Content*/}
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control br-ts-3 br-bs-3"
                      placeholder="Search"
                    />
                    <button
                      type="button"
                      className="btn btn-primary br-te-3 br-be-3"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Categories</h3>
                </div>
                <div className="card-body">
                  <div id="container">
                    <div className="filter-product-checkboxs">
                      {props.category &&
                        props.category.map((category) => {
                          return (
                            <label
                              className="custom-control custom-checkbox mb-3"
                              key={category.id}
                            >
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="checkbox1"
                                value={category.id}
                                onChange={(event) =>
                                  handleCheckboxChange(event, category.id)
                                }
                                checked={selectedCategories.includes(
                                  category.id
                                )}
                              />
                              <span className="custom-control-label" style={{lineHeight:'0.5rem'}}  >
                                <span>
                                  {category.name}
                                  <span className="label label-secondary float-end">
                                    {category.app_count}
                                  </span>
                                </span>
                              </span>
                            </label>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/*recent app*/}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Recent Aps</h3>
                </div>
                <div className="card-body">
                  <div className="list-catergory1">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        {/*   {Object.values(props.recentApps).map(
                          (recentAppsGroup) => {
                          return recentAppsGroup.map((Rapp) => ( */}
                        {Object.values(props.recentApps).map((rcapp) => {
                          return (
                            <li className="list-group-item d-flex border-bottom-0">
                              <img
                                src={`${config.apiUrl}${rcapp.image_url}`}
                                className="avatar br-5 avatar-xl me-3 my-auto"
                                alt="avatar-img"
                              />
                              <div>
                                <small className="d-block">
                                  {rcapp.category["name"]}
                                </small>
                                <a className="text-default font-weight-semibold">
                                  {rcapp.title}
                                </a>
                                <small className="d-block text-muted">
                                  1 day ago
                                </small>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*/Right Side Content*/}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AppsList;
