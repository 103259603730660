import React, { useEffect, useState } from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import ArticleDetail from "../component/Post/ArticleDetail";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import CommentForm from "../component/comment/CommentForm";
import Feedbacks from "../component/comment/Feedbacks";
import { useParams, useLocation } from "react-router-dom";
import APISerive from "../component/Data/APIService";
import { Link } from "react-router-dom";
import $ from "jquery";
import config from "../config";

function ArticlePostDetailPbyslug(props) {
  const [articleProps, setAarticleProps] = useState([]);
  const [recArticles, setRecArticles] = useState([]);
  const [category, setcategory] = useState([""]);
  const { slug } = useParams();
  const location = useLocation();

  //setAarticleProps(location.state)

  const [article, setArticle] = useState(null);
  const [feedbacks, setFeedbacks] = useState(null);

  const reloadFeedbacks = (newFeedbacks) => {
    setFeedbacks(newFeedbacks);
  };

  useEffect(() => {

    $("#global-loader").fadeOut("slow");

    const fetchData = async () => {
      try {
        const user_token = localStorage.getItem("token");
        console.log(slug);

        // Fetch article data
        const res = await APISerive.getArticleBySlug(slug);
        console.log(res.article_id);

        const articleData = await APISerive.getArticle(res.article_id);
        console.log("Article Data:", articleData);
        setArticle(articleData);

        // Fetch feedback data
        const feedbackData = await APISerive.getFeedback(res.article_id);
        console.log("Feedback Data:", feedbackData);
        setFeedbacks(feedbackData);

        //fetching data categories & ltest

        APISerive.BlogCategories().then((reponseData) => {
          setcategory(reponseData);
        });

        // recent posts

        APISerive.Articles_recent().then((resp) => {
          setRecArticles(resp);
        });
        // visited user
        if (res.article_id != null) {
          const formData = new FormData();
          formData.append("user", localStorage.getItem("user") || 0);
          formData.append("article", res.article_id);
          APISerive.Articles_Save_visited({
            user: localStorage.getItem("user") || 0,
            article: res.article_id
          }).catch((res)=>console.log('error occured during save visited'))
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [slug]);

  useEffect(() => {
    console.log(article);
    console.log(feedbacks); // This will log the updated 'article' and 'feedbacks' states
  }, [article, feedbacks]);

  return (
    <div>
      <Header />

      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>Blog Detail</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/blogs">BLogs</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    blog detail
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12">
              {article ? (
                <>
                  <ArticleDetail article={article} />
                  {feedbacks ? (
                    <Feedbacks feedbacks={feedbacks} reloadFeedbacks={reloadFeedbacks}/>
                  ) : (
                    <p>Loading feedback...</p>
                  )}
                  <CommentForm
                    article={article}
                    reloadFeedbacks={reloadFeedbacks}
                  />
                </>
              ) : (
                <p>Loading article...</p>
              )}
            </div>
            {/*Rightside Content*/}
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <button type="button" className="btn btn-primary">
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Blog Categories</h3>
                </div>
                <div className="card-body p-2">
                  <div className="list-catergory">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        {category &&
                          category.map((category) => {
                            return (
                              <li className="list-group-item">
                                <a
                                  href="javascript:void(0);"
                                  className="text-default-dark"
                                >
                                  <i className="fe fe-chevrons-right" />{" "}
                                  {category.name}
                                  <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                                    {category.post_count} Posts
                                  </span>
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Latest Posts</h3>
                </div>
                <div className="card-body p-3">
                  <div className="list-catergory1">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        {recArticles &&
                          Object.values(recArticles).map((recArticlesGroup) => {
                            return recArticlesGroup.map((Rarticle) => (
                              <li className="list-group-item d-flex border-bottom-0">
                                <img
                                  src={`${config.apiUrl}${Rarticle.image_url}`}
                                  className="avatar br-5 avatar-xl me-3 my-auto"
                                  alt="avatar-img"
                                />
                                <div className>
                                  <small className="d-block">
                                    {Rarticle.category["name"]}
                                  </small>
                                  <a className="text-default font-weight-semibold">
                                    {Rarticle.title}
                                  </a>
                                  <small className="d-block text-muted">
                                    1 day ago
                                  </small>
                                </div>
                              </li>
                            ));
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*/Rightside Content*/}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ArticlePostDetailPbyslug;
