import React from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import BlogList from "../component/blogList/blogList";
import NewsLetter from "../component/newsLetter/newsLetter";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import $ from 'jquery'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ArticlePost from "../component/Post/ArticlePost";
import { Link } from "react-router-dom";

function BlogPost() {
  useEffect(() => {
    $("#global-loader").fadeOut("slow");

  }, []);

 
  return (
    <div>
      <Header />
      <section>
  <div className="bannerimg cover-image background-size bg-background3" data-bs-image-src="../assets/images/banners/banner2.jpg">
    <div className="header-text mb-0">
      <div className="container">
        <div className="text-center text-white ">
          <h1 >Create your Blog</h1>
          <ol className="breadcrumb text-center">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to="/user-dashboard">Dashboard</Link></li>
            <li className="breadcrumb-item active text-white" aria-current="page">New Blog</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>
      



      <ArticlePost/>
      <Footer />
    </div>
  );
}

export default BlogPost;
