import React, { useEffect, useState } from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import NewsLetter from "../component/newsLetter/newsLetter";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import $ from "jquery";
import APISerive from "../component/Data/APIService";
import AppsList from "../component/products/AppsList";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function ProductsP() {
  const { catid, appname } = useParams();
  const [nextUrl, setnextUrl] = useState();
  const [previousUrl, setpreviousUrl] = useState();

  const reloadNextUrl = (newurl) => {
    setnextUrl(newurl);
  };

  const reloadPreviousUrl = (newurl) => {
    setpreviousUrl(newurl);
  };

  const [apps, setApps] = useState([]);
  const [recentApps, setRecentApps] = useState([]);
  const [category, setcategory] = useState([""]);
  const [message, setMessage] = useState("");

  const reloadApps = (updated) => {
    setApps(updated);
  };
  useEffect(() => {        
    
    document.title = "AIConsumer.Products";
    

    if (
      catid !== null &&
      catid !== undefined &&
      catid !== 0 &&
      catid !== "undefined" &&
      appname === undefined &&
      appname === "undefined" &&
      appname === null
    ) {
      console.log("appand caid are ",appname)
      APISerive.Apps_Free_ByCategory(catid)
        .then((resp) => setApps(resp))
        .catch((error) => {
          console.error("Error fetching blog:", error);
        });
    } else if (
      catid !== null &&
      catid !== undefined &&
      catid !== 0 &&
      catid !== "undefined" &&
      appname !== null &&
      appname !== undefined &&
      appname !== 0 &&
      appname !== "undefined"
    ) {

      APISerive.Apps_ByCategory_ByAppname(catid, appname)
        .then((resp) => {
          setApps(resp);
          
          setMessage("The Result for Applications named by "+appname+" are :")
        })
        .catch((error) => {
          console.error("Error fetching blog:", error);
        });
    } else {
      APISerive.Apps_published().then((resp) => {
        setApps(resp.results);
        setnextUrl(resp.next);
        setpreviousUrl(resp.previous);
      });
    }

    // calling category function for No of post to each caetgory

    APISerive.AppsCategories().then((reponseData) => {
      setcategory(reponseData);
    });

    // recent Apps
    APISerive.Apps_recent().then((resp) => {
      setRecentApps(resp);
    });

    $("#global-loader").fadeOut("slow");

  }, []);

  return (
    <div>
      <Header />
      {/* <Breadcrumb data={dataTosend} /> */}
      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>Application Products</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    App Products
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AppsList
        apps={apps}
        category={category}
        recentApps={recentApps}
        reloadApps={reloadApps}
        reloadNextUrl={reloadNextUrl}
        reloadPreviousUrl={reloadPreviousUrl}
        previousUrl={previousUrl}
        nextUrl={nextUrl}
        message={message}
      />
      <NewsLetter />
      <Footer />
    </div>
  );
}

export default ProductsP;
