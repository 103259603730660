import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import APISerive from "../Data/APIService";
import StarRating from "../starRating/Rating";
import config from "../../config";
function sanitizeAndRenderHTML(htmlContent) {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  return { __html: cleanHTML };
}

function ArticleDetail(props) {

  console.log("fromsdadfa")
  console.log(props.article.data.id)

/*   const handleRatingChange = (newRating) => {
    setRating(newRating);
    const formData = new FormData();
    formData.append("rating", newRating);
    APISerive.updateReview(
      formData,
      props.article.id,
      localStorage.getItem("user"),
      localStorage.getItem("token")
    ).catch((error) => {
      console.error("Error submitting review of blog:", error);
    });
  }; */

  /* useEffect(() => {
    if (props.article.id != null && localStorage.getItem("user")) {
      const formData = new FormData();
      formData.append("article", props.article.id);
      formData.append("user", localStorage.getItem("user"));

      APISerive.InsertReview(formData, localStorage.getItem("token"))
        .then(() => {})
        .catch((error) => {
          console.error("Error submitting review of blog:", error);
        });
    }
  }, [props.article.id]); */

  return (
    <div className="card blog-detail">
      <div className="card-body">
        <div className="item7-card-img br-7 mb-5">
          <img
            src={`${config.apiUrl}${props.article.data.image}`}
            alt={props.article.data.title}
            className="w-100"
          />
          <div className="item-card-text-bottom me-0">
            <h4 className="mb-0">{props.article.data.category_name}</h4>
          </div>
        </div>
        <a href="javascript:void(0);" className="text-dark">
        <h2 className="font-weight-semibold"><p>{props.article.data.title}</p></h2>
          <h5 className="">
            {" "}
            <p>{props.article.data.intro}</p>
          </h5>
        </a>
        <div className="item7-card-desc d-md-flex mb-2 mt-3">
          <a
            href="javascript:void(0);"
            className="font-weight-semibold fs-15 d-inline-block"
          >
            <i className="fe fe-calendar me-2 text-primary" />
            {new Date(props.article.data.pub_date).toLocaleDateString()}
          </a>
          <a
            href="javascript:void(0);"
            className="font-weight-semibold fs-15 d-inline-block"
          >
            <i className="fe fe-user me-2 text-primary" />
            {props.article.data.user_name}
          </a>
          <div className="ms-auto">
            <a
              href="javascript:void(0);"
              className="font-weight-semibold fs-15"
            >
              <div className="d-flex justify-content-between align-items-center mt-0 gap-2 ">Comments
               
                <i className="fe fe-message-circle me-2 text-primary" /> 
               { props.article.data.comment_count}
              </div>{" "}
            </a>
          </div>
        </div>

        {/* <p>{props.article.description}</p> */}
        <div
          dangerouslySetInnerHTML={sanitizeAndRenderHTML(
            props.article.data.description
          )}
        />
      </div>
    </div>
  );
}

export default ArticleDetail;
