import React,{useState,useEffect} from "react";
import config from "../../config";
import APISerive from "../Data/APIService";
import usrImg from '../../usr.jpg';

function FeedbacksApp(props) {
  const [userid, setUserid] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [editedComment, setEditedComment] = useState({ user: 0, message: "" });

  console.log("from feedback")
  console.log(props)

  const items = props.feedbacks;
  const userData = JSON.parse(localStorage.getItem("user_data"));

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    if (userData && userData.id) {
      setUserid(userData.id);
    }
  }, []); 

  const handleEditClick = (item) => {
    setEditMode(true);
    setEditedComment({ user: userid, message: item.message });

  };

  const handleCommentUpdate = async (id,app,user) => {
    try {
      
      await APISerive.updateComment_App(id,{message: editedComment.message,user:user,app:app},localStorage.getItem("token") );
      setEditMode(false);
      
      const feedbackData = await APISerive.getFeedback_app(app);
      console.log("just recieved ")
      console.log(feedbackData)
      props.reloadFeedbacks(feedbackData);
      

    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };


  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Comments</h3>
      </div>
      <div className="card-body">
        { Array.isArray(items) ? (items.map((item, index) => (
          <div key={index} className="media  mt-2 p-5 border br-7">
            <div className="d-flex me-3">
              <a href="javascript:void(0);">
                <img
                  className="media-object brround avatar-lg"
                  alt="64x64"
                  src={userData.profile_picture === null ? usrImg : `${config.apiUrl}${userData.profile_picture}`}
                />{" "}
              </a>
            </div>
            <div className="media-body">
            <div className="mt-0 mb-1 font-weight-semibold fs-15">
              {item.username} :
              <div className="fs-14 float-md-end d-block d-md-flex">
                <div
                  className="rating-star sm my-rating-5"
                  data-stars="4s"
                ></div>
              </div>
            </div>
            <small className="text-muted fs-13">
              
            </small>
            {editMode && item.user ===userid? (
              <>
                <textarea className="form-control"
                  value={editedComment.message}
                  onChange={(e) => setEditedComment({ ...editedComment, message: e.target.value })}
                />
                <button className="btn btn-mini btn-info mt-4" onClick={()=>handleCommentUpdate(item.id,item.app,item.user)}>Update</button>
              </>
            ) : (
              <>
              <p className="font-13 mb-2 mt-2">{item.message}</p>
              <div className="d-md-flex">
              {userid === item.user ? (
                <a  onClick={() => handleEditClick(item)} className="me-2 text-primary mt-1">
                  <i className="fa fa-mail-reply me-2" />
                  Edit
                </a>
              ) : null}
            </div> </>
            )}
           
          </div>
          </div>
        ))) : (<p>No Comment are yet</p>)}
      </div>
    </div>
  );
}

export default FeedbacksApp;
