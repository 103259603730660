import React ,{useEffect} from "react";
import LoginP from "../component/login//Login";
import Footer from "../component/footer/footer";
import Header from "../component/header/Header";
import $ from 'jquery'; 
function Login() {
  useEffect(() => {
    document.title = 'AIConsumer.Login';
    $("#global-loader").fadeOut("slow")
  }, []);
  return (
    <div>
      <Header />
      <LoginP />
      <Footer />
    </div>
  );
}

export default Login;
