import React, { useState ,useEffect} from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import MyModal from "./MyModelApp";
import "./Mymodel.css";
import $ from 'jquery';
import "datatables.net";
import { confirmAlert } from "react-confirm-alert";
import config from "../../config";
import "react-confirm-alert/src/react-confirm-alert.css";

function Apps(props) {
  console.log(props.apps);

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("0");

  useEffect(() => {
    if (props.apps && props.apps.length > 0) {
      $("#dtApps").DataTable();
    }
  }, [props.apps]);


  const openModal = (id) => {
    setShowModal(true);
    setId(id);
    console.log("id from " + id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const deleteApps = (id) => {
    if (id != null) {
      APISerive.Apps_delete(id, localStorage.getItem("token"))
        .then(() => {
          
          toast.success("Success :Your App is removed!", {
            position: toast.POSITION.TOP_CENTER,
          });
          APISerive.apps(localStorage.getItem("token"))
            .then((resp) => {
              props.reloadApps(resp); // Call the parent's updateArticles function
            })
            .catch((error) => {
              console.error("Error fetching updated Apps:", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting Apps:", error);
        });
    }
  };

  const changePublishStatus = (id, status) => {
    if (id != null) {
      status = status === "unpublished" ? "published" : "unpublished";
      APISerive.Apps_setStatus(id, status, localStorage.getItem("token"))
        .then(() => {
          toast.success("Success : App Status is changed!", {
            position: toast.POSITION.TOP_CENTER,
          });
          APISerive.apps(localStorage.getItem("token"))
            .then((resp) => {
              props.reloadApps(resp); // Call the parent's updateArticles function
            })
            .catch((error) => {
              console.error("Error fetching updated articles:", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting blog:", error);
        });
    }
  };
  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this blog?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteApps(id)
        },
        {
          label: "No",
          onClick: () => {} // Do nothing 
        }
      ]
    });
  };
  return (
    <div>
      <div className="card mb-0">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title">My Apps</h3>
          </div>
          <div className="text-right">
            <Link to="/products/upload-new-product" className="btn btn-primary ad-post">
              {" "}
              <i className="fe fe-plus me-1"></i> Upload App
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="ads-tabs">
            <table id="dtApps" className="table table-bordered table-hover mb-0 text-nowrap">
              <thead>
                <tr>
                  <th />
                  <th>App</th>
                  <th>Category</th>
                  <th>Ad Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {props.apps &&
                  props.apps.map((app,index) => {
                    return (
                      <tr key={app.id}>
                        <td style={{textAlign:'center',verticalAlign:'middle'}}>
                        {index + 1}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>
                          <div className="media mt-0 mb-0">
                            <div className="card-aside-img mb-2">
                            {

                            }
                            <a href="javascript:void(0);" />
                            <img
                              src={`${config.apiUrl}${app.image_url}`}
                              alt={app.image_url}
                              />
                            </div>
                            <div className="media-body">
                              <div className="card-item-desc ms-4 p-0 ">
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <h4 className="font-weight-semibold">
                                    {app.title}
                                  </h4>
                                </a>
                                <a href="javascript:void(0);">
                                  <i className="fa fa-clock-o me-1" />{" "}
                                  {new Date(
                                    app.inserted_datetime
                                  ).toLocaleString()}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>categories</td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            className={
                              app.status === "published"
                                ? "badge badge-success"
                                : "badge badge-warning"
                            }
                          >
                            {app.status === "published"
                              ? "Published"
                              : "Unpublished"}
                          </a>
                        </td>
                        <td>
                          <a
                            className="btn btn-success btn-sm text-white"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Edit"
                            href="edit-app.html"
                          >
                            <i className="fa fa-pencil" />
                          </a>
               { /*           <button
                            onClick={() => confirmDelete(app.id)}
                            className="btn btn-danger btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Delete"
                          >
                            
                            <i className="fa fa-trash-o" />
                          </button> */}
                          <button
                            onClick={() =>
                              changePublishStatus(app.id, app.status)
                            }
                            className="btn btn-info btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Save to Wishlist"
                          >
                            <i className="fa fa-gavel" />
                          </button>

                          <button
                            onClick={() => openModal(app.id)}
                            type="button"
                            class="btn btn-primary"
                            className="btn btn-primary btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="View"
                          >
                            <i className="fa fa-eye" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <MyModal show={showModal} onHide={closeModal} id={id} />
    </div>
  );
}

export default Apps;
