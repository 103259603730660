import React, { useState ,useEffect} from "react";
import MyModalblog from "./MyModel";
import { Button } from "bootstrap";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
import "./Mymodel.css";
import { Link } from "react-router-dom";
import config from "../../config";
import $ from 'jquery';
import "datatables.net";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function DashboardRightBlog(props) {
  console.log(props.articles);

  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState("0");

  useEffect(() => {
    if (props.articles && props.articles.length > 0) {
      $("#dtBlog").DataTable();
    }
  }, [props.articles]);

  const openModal = (id) => {
    setShowModal(true);
    setId(id);
    console.log("id from " + id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const deleteBlog = (id) => {
    if (id != null) {
      APISerive.Articles_delete(id, localStorage.getItem("token"))
        .then(() => {
          toast.success("Success :Your Blog is removed!", {
            position: toast.POSITION.TOP_CENTER,
          });
          APISerive.Articles(localStorage.getItem("token"))
            .then((resp) => {
              props.reloadBlogs(resp); // Call the parent's updateArticles function
            })
            .catch((error) => {
              console.error("Error fetching updated articles:", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting blog:", error);
        });
    }
  };

  const changePublishStatus = (id, status) => {
    if (id != null) {
      status = status === "unpublished" ? "published" : "unpublished";
      APISerive.Articles_setStatus(id, status, localStorage.getItem("token"))
        .then(() => {
          toast.success("Success : Blog Status is changed!", {
            position: toast.POSITION.TOP_CENTER,
          });
          APISerive.Articles(localStorage.getItem("token"))
            .then((resp) => {
              props.reloadBlogs(resp); // Call the parent's updateArticles function
            })
            .catch((error) => {
              console.error("Error fetching updated articles:", error);
            });
        })
        .catch((error) => {
          console.error("Error deleting blog:", error);
        });
    }
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm",
      message: "Are you sure you want to delete this blog?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBlog(id)
        },
        {
          label: "No",
          onClick: () => {} // Do nothing 
        }
      ]
    });
  };

  return (
    <div>
      <div className="card mb-0">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title">My Blogs</h3>
          </div>
          <div className="text-right">
            <Link to="/blog/create-new-blog" className="btn btn-primary ad-post">
              {" "}
              <i className="fe fe-plus me-1"></i> Create Blog
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="ads-tabs">
            <table id="dtBlog" className="table table-bordered table-hover mb-0 text-nowrap">
              <thead>
                <tr>
                  <th  />
                  <th >Blog</th>
                  <th >Category</th>
                  <th >Ad Status</th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.articles &&
                  props.articles.map((article,index) => {
                    return (
                      <tr key={article.id}>
                        <td style={{textAlign:'center',verticalAlign:'middle'}}>
                         {index+1}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>
                          <div className="media mt-0 mb-0">
                            <div className="card-aside-img mb-2">
                              <a href="javascript:void(0);" />
                              <img
                                src={`${config.apiUrl}${article.image_url}`}
                                alt={article.img}
                              />
                            </div>
                            <div className="media-body">
                              <div className="card-item-desc ms-4 p-0 ">
                                <a
                                  href="javascript:void(0);"
                                  className="text-dark"
                                >
                                  <h4 className="font-weight-semibold">
                                    {article.title}
                                  </h4>
                                </a>
                                <a href="javascript:void(0);">
                                  <i className="fa fa-clock-o me-1" />{" "}
                                  {new Date(article.pub_date).toLocaleString()}
                                </a>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{article.category_name}</td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            className={
                              article.status === "published"
                                ? "badge badge-success"
                                : "badge badge-warning"
                            }
                          >
                            {article.status === "published"
                              ? "Published"
                              : "Unpublished"}
                          </a>
                        </td>
                        <td>
                          <a
                            className="btn btn-success btn-sm text-white"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Edit"
                            href="edit-app.html"
                          >
                            <i className="fa fa-pencil" />
                          </a>
                       {/*    <button
                          onClick={() => confirmDelete(article.id)}
                            className="btn btn-danger btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Delete"
                          >
                            
                            <i className="fa fa-trash-o" />
                          </button> */}
                          <button
                            onClick={() =>
                              changePublishStatus(article.id, article.status)
                            }
                            className="btn btn-info btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Save to Wishlist"
                          >
                            <i className="fa fa-gavel" />
                          </button>

                          <button
                            onClick={() => openModal(article.id)}
                            type="button"
                            class="btn btn-primary"
                            className="btn btn-primary btn-sm text-white m-1"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="View"
                          >
                            <i className="fa fa-eye" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <MyModalblog show={showModal} onHide={closeModal} id={id} />
    </div>
  );
}

export default DashboardRightBlog;
