import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import { Link,useNavigate } from "react-router-dom";
import config from "../../config";

import StarRating from "../starRating/Rating";
import './hero.css'

function Hero() {
  const [apps, setApps] = useState([]);
  const [appnames, setAppnames] = useState("");
  const [selectedCategory, SetselectedCategory] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  const handleCategoryChange = (e) => {
    const selectedCategoryValue = e.target.value;
    console.log("Selected Category:", selectedCategoryValue);
    SetselectedCategory(selectedCategoryValue);
  };

  const setAppname = (e) => {
    setAppnames(e.target.value);
  };

  const searchApp = (e) => {
    e.preventDefault();
    console.log("Selected Category in searchApp:", selectedCategory);
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      console.log(selectedCategory);
      navigate(`/products/${appnames}/${selectedCategory}`);
      
      /* APISerive.Apps_ByCategory_ByAppname(selectedCategory, appnames)
        .then((resp) => {
          setApps(resp);
          console.log("heroo");
          console.log(apps);
          setIsVisible(true);
          window.scrollTo({
            top: window.scrollY + 350,
            behavior: "auto", 
          });
        })
        .catch(); */
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const validateForm = () => {
    const newErrors = {};
    console.log(selectedCategory)
    if (!selectedCategory || !selectedCategory.trim()) {
      newErrors.title = "Please select the category";
    }
    if (!appnames.trim()) {
      newErrors.title = "Please write some application names";
    }
    return newErrors;
  };

  useEffect(() => {
    console.log("app values is ");
    console.log(apps);
  }, [apps]);

  

  return (
    <div>
      {/*Sliders Section*/}

      <div
        className="banner-1 cover-image background-size  sptb-2 bg-background"
        data-bs-image-src="../assets/images/banners/banner1.jpg"
      >
        <div className="header-text1 mb-0">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 d-block mx-auto">
                <div className="text-center text-white ">
                  <h1 className="mb-0">Download the Resources Listed</h1>
                  <p className="mt-2">
                    It is a long established fact that a reader will be
                    distracted by the readable. Mauris ut cursus nunc.
                  </p>
                </div>
                <div className=" search-background">
                  <div className="form row no-gutters">
                    <div className="form-group  col-xl-6 col-lg-5 col-md-12 mb-0">
                      <input
                        type="text"
                        className="form-control input-lg border-end-0"
                        id="text"
                        value={appnames}
                        onChange={setAppname}
                        required
                        placeholder="Search App"
                      />
                    </div>
                    <div className="form-group col-xl-4 col-lg-4 select2-lg  col-md-12 mb-0">
                      <select
                        className="form-control "
                        data-placeholder="Select"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        style={{height: '2.840rem'}}
                      >
                        <optgroup label="App categories lists are">
                          <option value="">Categories</option>{" "}
                          {/* Set an empty value for the default option */}
                          <option value={3}>Windows</option>
                          <option value={4}>Android</option>
                          <option value={5}>Mac</option>
                          <option value={7}>Business App</option>
                        </optgroup>
                      </select>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-12 mb-0">
                      <button
                        onClick={searchApp}
                        className="btn btn-lg btn-block btn-primary"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
                <div className="soft-categories">
                  <p className="categories-header"> CATEGORIES</p>
                  <ul>
                    <li>
                      <Link to="/products/4">
                        <img
                          src="../assets/images/categories-svg/android.svg"
                          alt="Dash"
                        />
                        <span>Android</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/3">
                        <img
                          src="../assets/images/categories-svg/windows.svg"
                          alt="Dash"
                        />
                        <span>Windows</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/5">
                        <img
                          src="../assets/images/categories-svg/apple.svg"
                          alt="Dash"
                        />
                        <span>Mac</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/products/7">
                        <img
                          src="../assets/images/categories-svg/business.svg"
                          alt="Dash"
                        />
                        <span>BusinessApp</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /header-text */}
      </div>
      {apps && apps.length > 0 && isVisible ? (
        <div className="container mt-6 closable-div">
          <button className="close-button" onClick={handleClose}>
            X
          </button>
          <div className="section-title center-block text-center">
            <h4 className="mb-4">
              The search for <span>{appnames}</span> Apps :
            </h4>
            <div className="row justify-content-center ">
              {apps &&
                apps.map((item,index) => {
                  return (
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                      <div className="card mb-0 overflow-hidden">
                        <div class="ribbon ribbon-top-left text-danger">
                          <span
                            class={
                              item.category_name === ("Auto" || "windows")
                                ? "bg-danger"
                                : "bg-success"
                            }
                          >
                            {item.category_name} 
                          </span>
                        </div>
                        <div className="item-card7-imgs">
                          <a href="app-details.html" />
                          <img
                            src={`${config.apiUrl}${item.image}`}
                            alt="Dash"
                            className="p-0"
                          />
                        </div>
                        <div className="card-body">
                          <div className="item-card7-desc">
                            <div className="item-card7-text">
                              <a
                                href="app-details.html"
                                className="text-dark text-header"
                              >
                                <h4 className="mb-1">{item.title}</h4>
                              </a>
                              <div className="d-inline-flex">
                                <div className=" d-inline-flex">
                                  <div
                                    className="rating-star sm my-rating-5"
                                    data-stars="4s"
                                  ></div>{" "}
                                  <StarRating value={item.average_rating} />(
                                  {item.average_rating}/5)
                                </div>
                              </div>
                            </div>
                            <ul className="item-cards7-ic software-list mb-0 mt-3">
                              <li>
                                <a
                                  href="google.com"
                                  className="icons text-muted "
                                >
                                  <i className="fa fa-database text-muted me-1" />{" "}
                                  {item.size}
                                  MB
                                </a>
                              </li>
                              <li>
                                <a
                                  href="google.com;"
                                  className="icons text-muted "
                                >
                                  <i className="fa fa-low-vision text-muted me-1" />
                                  {item.visit_count}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="google.com;"
                                  className="icons text-muted "
                                >
                                  <i className="fa fa-refresh text-muted me-1" />{" "}
                                  20.0.4 v{" "}
                                </a>
                              </li>
                              <li>
                                <a
                                  href="google.com;"
                                  className="icons text-muted "
                                >
                                  <i className="fa fa-comment-o text-muted me-1" />{" "}
                                  {item.comments_count} &nbsp; Reviews
                                </a>
                              </li>
                            </ul>
                            <p className="mb-0">
                              Various versions have evolved over the years,
                              sometimes
                            </p>
                            <Link
                              to={item.applink}
                              className="btn btn-primary mt-3"
                            >
                              Visit
                            </Link>
                          </div>
                        </div>
                        <div className="card-footer pt-3 pb-3">
                          <div className="footerimg d-flex mt-0 mb-0">
                            <div className="d-flex footerimg-l mb-0 align-items-center">
                              <i className="fa fa-calendar me-2" />
                              {new Date(
                                item.inserted_datetime
                              ).toLocaleDateString()}
                            </div>
                            <div className="footerimg-r ms-auto">
                              <a href="google.com;" title="Categorie">
                                {item.category_name}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                    
                  );
                })}
            </div>
          </div>
        </div>
      ) : null}
      {/*/Sliders Section*/}
    </div>
  );
}

export default Hero;
