import React from 'react'
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import Categories from "../component/categories/categories";
import NewsLetter from "../component/newsLetter/newsLetter";

import Footer from "../component/footer/footer";

function Categoriesp() {
  const dataTosend = { title: "Categories" };
  return (
    <div>
    <Breadcrumb data={dataTosend} />
        <Categories/>
        <NewsLetter/>
        <Footer/>
      
    </div>
  )
}

export default Categoriesp
