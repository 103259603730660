import React,{useState,useEffect} from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";

function Profile() {
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        address: "",
        description: "",
        email:""
      });
    
      useEffect(() => {
        const userData = JSON.parse(localStorage.getItem("user_data"));
    
        if (userData) {
          setFormData({
            first_name: userData.first_name || "",
            last_name: userData.last_name || "",
            phone_number: userData.phone_number || "",
            address: userData.address || "",
            description: userData.description || "",
            email: userData.email || "",
          });
        }
      }, []); 


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profile_picture: file });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {

        APISerive.update_user( formDataToSend,localStorage.getItem('token')
        ).then(toast("Success : Your profile is updated !"));

      
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <div className="card mb-0">
        <div className="card-header">
          <h3 className="card-title">Edit Profile</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    id="first_name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="last_name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="form-group">
                  <label className="form-label">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6">
                <div className="form-group">
                  <label className="form-label">Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Number"
                    id="phone_number"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Home Address"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">About Me</label>
                  <textarea
                    rows={5}
                    className="form-control"
                    placeholder="Enter About your description"
                    defaultValue={""}
                    type="text"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-3">
                  <label htmlFor="formFile" className="form-label">
                    Upload Images
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    id="profile_picture"
                    name="profile_picture"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <button type="submit" className="btn btn-primary">
              Updated Profile
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Profile;
