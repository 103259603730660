import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import DOMPurify from "dompurify";
import "./Mymodel.css";
import config from "../../config";

function sanitizeAndRenderHTML(htmlContent) {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  return { __html: cleanHTML };
}

const MyModal = ({ show, onHide, id }) => {
  const [article, setArticle] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const user_token = localStorage.getItem("token");
        // Fetch article data
        const article = await APISerive.getArticle(id);

        setArticle(article);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);
  return (
    <div
      className={`modal fade bd-example-modal-lg modalBackground ${
        show ? "show" : ""
      }`}
      style={{ display: show ? "block" : "none" }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content modalContainer">
          <div className="modal-header">
            <h5 className="modal-title">Blog Preview</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onHide}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className=" body">
            <div className="card blog-detail">
              <div className="card-body">
                {article.data && !article.null? ( // Check if article is not null or undefined
                  <>
                    <div className="item7-card-img br-7 mb-5">
                      <img
                      src={`${config.apiUrl}${article.data.image_url}`}
                        alt={article.data.title}
                        className="w-100"
                      />
                      <div className="item-card-text-bottom me-0">
                        <h4 className="mb-0">{article.category_name}</h4>
                      </div>
                    </div>
                    <a href="javascript:void(0);" className="text-dark">
                      <h5 className="font-weight-semibold">
                        <p>{article.data.intro}</p>
                      </h5>
                    </a>
                    <div className="item7-card-desc d-md-flex mb-2 mt-3">
                      <a
                        href="javascript:void(0);"
                        className="font-weight-semibold fs-15 d-inline-block"
                      >
                        <i className="fe fe-calendar me-2 text-primary" />
                        Nov-28-2018
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="font-weight-semibold fs-15 d-inline-block"
                      >
                        <i className="fe fe-user me-2 text-primary" />
                        {article.data.user_name}
                      </a>
                      <div className="ms-auto">
                        
                      </div>
                    </div>
                    <div
                      style={{ fontFamily: "'Times New Roman', Times, serif" ,fontSize:"12px",textAlign:"justify",lineHeight:1 }}
                    >
                      <div
                        dangerouslySetInnerHTML={sanitizeAndRenderHTML(
                          article.data.description
                        )}
                      />
                    </div>
                  </>
                ) : (
                  // Render a message or component when article is null or undefined
                  <p>No article found.</p>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={onHide}
            >
              Close
            </button>
            {/*   <button type="button" className="btn btn-primary">
              Save changes
  </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyModal;
