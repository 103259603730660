import React from 'react'
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import BlogList from "../component/blogList/blogList";
import NewsLetter from "../component/newsLetter/newsLetter";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
function Blog() {
  const dataTosend = { title: "Blog" };
  return (
    <div>
    <Breadcrumb data={dataTosend} />
      <BlogList/>
      <Footer/>
    </div>
  )
}

export default Blog
