import config from "../../config";

export default class APISerive {
  static getCookie(name) {
    const cookieValue = document.cookie.match(
      `(^|;)\\s*${name}\\s*=(.*?)($|;)`
    );
    return cookieValue ? cookieValue[2] : null;
  }

  static UpdateArticle(article_id, body, token) {
    return fetch(`${config.apiUrl}/api/articles/${article_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  
  static updateComment_blog(article_id, body, token) {
    return fetch(`${config.apiUrl}/api/comments/update/${article_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static updateComment_App(app_id, body, token) {
    return fetch(`${config.apiUrl}/api/app/comments/update/${app_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify(body),
    }).then((resp) => resp.json());
  }

  static InsertArticle(formData, token) {
    return fetch(`${config.apiUrl}/api/articles/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((resp) => resp.json());
  }
  static InsertComment(formData, token) {
    return fetch(`${config.apiUrl}/api/comments/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((resp) => {
        if (!resp.ok) {
          console.log(resp);
          throw new Error("Network response was not ok saasa");
        }

        return resp.json();
      })
      .catch((error) => {
        console.error("Error:", error);

        return Promise.reject(error);
      });
  }
  static InsertComment_app(formData, token) {
    return fetch(`${config.apiUrl}/api/app/comments/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    })
      .then((resp) => {
        if (!resp.ok) {
          console.log(resp);
          throw new Error("Network response was not ok saasa");
        }

        return resp.json();
      })
      .catch((error) => {
        console.error("Error:", error);

        return Promise.reject(error);
      });
  }

  static InsertContactUs(formData) {
    return fetch(`${config.apiUrl}/api/contactus/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((resp) => resp.json());
  }

  static Insert_subscribe(formData) {
    return fetch(`${config.apiUrl}/api/subscribe/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then((resp) => resp.json());
  }

  static DeleteArticle(article_id, token) {
    return fetch(`${config.apiUrl}/api/articles/${article_id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    });
  }

  static LoginUser(body) {
    return fetch(`${config.apiUrl}/user/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  static RegisterUser(body) {
    return fetch(`${config.apiUrl}/user/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((resp) => {
      if (resp.status === 200) {
        return resp.json();
      }

      /*  else {
          throw new Error(`Request failed with status ${resp.status}`);
        } */
    });
    /*  .catch((error) => {
        console.log(error);
        throw error;
      }); */
  }

  static update_user(formData, token) {
    return fetch(`${config.apiUrl}/api/updateUser/`, {
      method: "PUT",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((resp) => resp.json());
  }

  static getArticle(id) {
    return fetch(`${config.apiUrl}/api/article/${id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  }

  static getApp(id) {
    return fetch(`${config.apiUrl}/api/app/${id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  }

  static getFeedback(id) {
    return fetch(`${config.apiUrl}/api/comments/${id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  }
  static getFeedback_app(id) {
    return fetch(`${config.apiUrl}/api/app/comments/${id}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  }
  static getArticleBySlug(slug) {
    return fetch(`${config.apiUrl}/api/articles/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((resp) => resp.json());
  }
  static getUserId(token) {
    return fetch(`${config.apiUrl}/api/get_user_id/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static BlogCategories(token) {
    return fetch(`${config.apiUrl}/api/categories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static AppsCategories() {
    return fetch(`${config.apiUrl}/api/apps/categories/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles(token) {
    return fetch(`${config.apiUrl}/api/articles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_published() {
    return fetch(`${config.apiUrl}/api/published-articles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_published_byCategories(categoryIds) {
    const categoryIdsArray = Array.isArray(categoryIds)
      ? categoryIds
      : [categoryIds];

    const categoryIdsString = categoryIdsArray.join(",");

    return fetch(
      `${config.apiUrl}/api/get-blogs-by-categories/?category_ids=${categoryIdsString}`,
      {
        method: "GET", // Use GET
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_recent() {
    return fetch(`${config.apiUrl}/api/published-recent-articles/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_Save_visited(formdata) {
    return fetch(`${config.apiUrl}/api/record-visited-article/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_Save_visited(formdata) {
    return fetch(`${config.apiUrl}/api/app/record-visited-app/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_delete(id, token) {
    return fetch(`${config.apiUrl}/api/articles/delete/${id}/`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_get_app_rating(id) {
    return fetch(`${config.apiUrl}/api/apps/${id}/average-rating/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Articles_setStatus(id, newStatus, token) {
    return fetch(`${config.apiUrl}/api/articles/setstatus/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ status: newStatus }),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static insert_apps(formData, token) {
    return fetch(`${config.apiUrl}/api/apps/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((resp) => resp.json());
  }

  static apps(token) {
    return fetch(`${config.apiUrl}/api/apps/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_setStatus(id, newStatus, token) {
    return fetch(`${config.apiUrl}/api/apps/setstatus/${id}/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ status: newStatus }),
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_delete(id, token) {
    return fetch(`${config.apiUrl}/api/apps/delete/${id}/`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_published() {
    return fetch(`${config.apiUrl}/api/published-apps/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_published_byCategories(categoryIds,other_param) {
    const categoryIdsArray = Array.isArray(categoryIds)
      ? categoryIds
      : [categoryIds];

    const categoryIdsString = categoryIdsArray.join(",");

    return fetch(
      `${config.apiUrl}/api/get-apps-by-categories/?category_ids=${categoryIdsString}&other_param=${other_param}`,
      {
        method: "GET", // Use GET
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_recent() {
    return fetch(`${config.apiUrl}/api/apps/published-recent-apps/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  //dashboard

  static Apps_Free_ByCategory(catid) {
    return fetch(`${config.apiUrl}/api/apps/home/free/${catid}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_ByCategory_ByAppname(catid, title) {
    return fetch(
      `${config.apiUrl}/api/apps/home/categories/${catid}/app/${title}/`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_ByCategory(catid) {
    return fetch(`${config.apiUrl}/api/apps/home/${catid}/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Testimonials() {
    return fetch(`${config.apiUrl}/api/testimonial/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static get_all_numbers_home() {
    return fetch(`${config.apiUrl}/api/apps/home/get_all_numbers/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  static Apps_mostviewd_ByCategory() {
    return fetch(`${config.apiUrl}/api/apps/home/topvisited/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .catch((error) => {
        console.error(error);
      });
  }

  // Review

  static InsertReview(formData, token) {
    return fetch(`${config.apiUrl}/api/reviews/`, {
      method: "POST",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    }).then((resp) => resp.json());
  }

  static updateReview(formData, article_id, userId, token) {
    return fetch(
      `${config.apiUrl}/api/reviews/${userId}/${article_id}/update_rating/`,
      {
        method: "POST",
        headers: {
          Authorization: `Token ${token}`,
        },
        body: formData,
      }
    ).then((resp) => resp.json());
  }
}
