import React, { useState } from 'react'
import Header from '../component/header/Header'
import AppDetail from '../component/products/AppDetail'
import Footer from "../component/footer/footer";
import { useParams ,useLocation } from "react-router-dom";
function App_detailById() {
  const { id } = useParams();  

  return (
    <div>
      <Header/>
      <AppDetail idApp={id}/>
      <Footer/>
    </div>
  )
}

export default App_detailById
