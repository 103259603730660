import React, { useState,useEffect } from "react";
import config from "../../config";
import usrImg from '../../usr.jpg';
function DashboardLeft(props) {
  const [activeTab, setActiveTab] = useState(1);
  const [generalData, setGeneralData] = useState({
    first_name: "",
    user_name: "",
    image: null,
  });

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    console.log(userData);
    if (userData) {
      setGeneralData({
        first_name: userData.first_name || "",
        user_name: userData.username || "",
        image: userData.profile_picture || null,
      });
    }
  }, []); 

  const changPageTab = (id) => {
    props.reloadPages(id);
    setActiveTab(id);
  };
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">My Dashboard</h3>
        </div>
        <div className="card-body text-center item-user ">
          <div className="profile-pic">
            <div className="profile-pic-img">
              <span
                className="bg-success dots"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="online"
              />
              <img
              src={generalData.image === null ? usrImg : `${config.apiUrl}${generalData.image}`}
               
                className="brround "
                alt="user"
              />
            </div>
            <a href="userprofile.html" className="text-dark">
              <h4 className="mt-3 mb-0 font-weight-semibold">{generalData.first_name}</h4>
            </a> 
          </div>
        </div>
        <aside className="app-sidebar doc-sidebar my-dash">
          <div className="app-sidebar__user clearfix">
            <ul className="side-menu">
              <li>
                <a
                  href="#"
                  className={`side-menu__item ${
                    activeTab === 1 ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    changPageTab(1);
                  }}
                >
                  <i className="fa fa-book" />
                  <span className="side-menu__label ms-2">Manage Blog</span>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className={`side-menu__item ${
                    activeTab === 2 ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    changPageTab(2);
                  }}
                >
                  <i className="icon icon-user" />
                  <span className="side-menu__label ms-2">Manage Profile</span>
                </a>
              </li>
              <li>
              <a
                href="#"
                className={`side-menu__item ${
                  activeTab === 3 ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  changPageTab(3);
                }}
              >
                <i className="fa fa-product-hunt" />
                <span className="side-menu__label ms-2">Manage Apps</span>
              </a>
            </li>
           
            </ul>
          </div>
        </aside>
      </div>
    
      <div className="card ">
        <div className="card-header">
          <h3 className="card-title">Safety Tips For Buyers</h3>
        </div>
        <div className="card-body">
          <ul className="list-unstyled widget-spec  mb-0">
            <li className>
              <i className="fa fa-check text-success" aria-hidden="true" /> Meet
              Seller at public Place
            </li>
            <li className>
              <i className="fa fa-check text-success" aria-hidden="true" />{" "}
              Check item before you buy
            </li>
            <li className>
              <i className="fa fa-check text-success" aria-hidden="true" /> Pay
              only after collecting item
            </li>
            <li className="ms-5 mb-0">
              <a href="tips.html"> View more..</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DashboardLeft;
