import React, { useState, useEffect } from "react";
import DashboardLeft from "../component/dashboard/DashboardLeft";
import DashboardRightBlog from "../component/dashboard/DashboardRightBlog";
import Header from "../component/header/Header";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import APISerive from "../component/Data/APIService";
import Profile from "../component/dashboard/Profile";
import Apps from "../component/dashboard/Apps";
import Footer from "../component/footer/footer";
import $ from "jquery";
import { Link } from "react-router-dom";

function Dashboard() {
  const [articles, setArticles] = useState([]);
  const [apps, setApps] = useState([]);
  const [pages, setPages] = useState(1);

  const reloadPages = (newPage) => {
    setPages(newPage);
  };
  const reloadBlogs = (newArticles) => {
    setArticles(newArticles);
  };

  const reloadApps = (newApps) => {
    setApps(newApps);
  };

  //caling blog at startPage
  useEffect(() => {

    document.title = 'AIConsumer.Dashboard';
    $("#global-loader").fadeOut("slow");
    APISerive.Articles(localStorage.getItem("token"))
      .then((resp) => setArticles(resp))
      .catch((error) => {
        console.error("Error fetching blog:", error);
      });

    // Apps

    APISerive.apps(localStorage.getItem("token"))
      .then((resp) => setApps(resp))
      .catch((error) => {
        console.error("Error fetching blog &apps :", error);
      });
      $("#global-loader").fadeOut("slow");

  }, []);

  const dataTosend = { title: "My Dashboard" };

  return (
    <div>
      <Header />
      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>My Dashboard</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Dashboard
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-12 col-md-12">
              <DashboardLeft reloadPages={reloadPages} />
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12">
              {pages === 1 ? (
                <DashboardRightBlog
                  articles={articles}
                  reloadBlogs={reloadBlogs}
                />
              ) : pages === 2 ? (
                <Profile />
              ) : pages === 3 ? (
                <Apps apps={apps} reloadApps={reloadApps} />
              ) : (
                <p></p>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Dashboard;
