import React, { useState, useEffect } from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import BlogList from "../component/blogList/blogList";
import NewsLetter from "../component/newsLetter/newsLetter";
import Contactus from "../component/contactUs/Contactus";
import Footer from "../component/footer/footer";
import Header from "../component/header/Header";
import $ from "jquery";
import { Link } from "react-router-dom";


function ContactUs() {
  useEffect(() => {
    document.title = 'AIConsumer.contact-us';
    $("#global-loader").fadeOut("slow");
  }, []);

  const dataTosend = { title: "Contact Us" };

  return (
    <div>
      <Header />
      {/* <Breadcrumb data={dataTosend} /> */}

      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>Contact Us</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li
                  className="breadcrumb-item active text-white" aria-current="page"
                  >
                      Contact Us
                    
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Contactus />
      <Footer />
    </div>
  );
}

export default ContactUs;
