import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import APISerive from "../Data/APIService";
import config from "../../config";
import './topApp.css';
import $ from 'jquery';

function TopApps() {
  const [apps, setApps] = useState([]);
  const [activeTab, setActiveTab] = useState(3);

  const get_Application_free = (id) => {
    $("#global-loader").fadeIn("slow");
    APISerive.Apps_Free_ByCategory(id)
      .then((resp) => setApps(resp))
      .catch((error) => {
        console.error("Error fetching blog:", error);
      }).finally($("#global-loader").fadeOut("slow"));
  };

  const categoryClickhandler = (id) => {
    get_Application_free(id);
    setActiveTab(id);
  };  

  useEffect(() => {
    get_Application_free(3);

  }, []);

  return (
    <div>
      <section className="sptb">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>
              Top <span>Free</span> Apps
            </h2>
            <p>
              Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur
              vehicula
            </p>
          </div>
          <div className="panel panel-primary">
            <div>
              <div className="tabs-menu ">
                {/* Tabs */}
                <ul className="nav panel-tabs eductaional-tabs ">
                  <li  className={`ms-2 ${activeTab === 4 ? 'active' : ''}`}>
                    <button
                      onClick={() => categoryClickhandler(4)}
                      className="btn btn-primary btn-square"
                    >
                      <i className="mdi mdi-android me-1 icon-color-white" />
                      Android
                    </button>
                  </li>
                  <li  className={`ms-2 ${activeTab === 5 ? 'active' : ''}`}>
                    <button
                      onClick={() => categoryClickhandler(5)}
                      className="btn btn-primary btn-square"
                    >
                      <i className="fa fa-apple me-1 icon-color-white" />
                      Mac
                    </button>
                  </li>
                  <li  className={`ms-2 ${activeTab === 3 ? 'active ' : ''}`}>
                    <button
                      onClick={() => categoryClickhandler(3)}
                      className="btn btn-primary btn-square"
                    >
                      <i className="fa fa-windows me-1 icon-color-white" />
                      Windows
                    </button>
                  </li>
                  <li  className={`ms-2 ${activeTab === 7 ? 'active' : ''}`}>
                    <button
                      onClick={() => categoryClickhandler(7)}
                      className="btn btn-primary btn-square"
                    >
                      <i className="fa fa-briefcase me-1 icon-color-white"></i>
                      Business App
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="panel-body">
              <div className="tab-content">
                <div className="tab-pane active show" id="index1">
                  <div className="row justify-content-center ">
                    {apps &&
                      apps.map((item) => {
                        return (
                          <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6">
                            <div className="card overflow-hidden">
                              <div className="power-ribbon power-ribbon-top-left text-warning">
                                <span className="bg-warning">
                                  <i className="fa fa-bolt" />
                                </span>
                              </div>
                              <div className="item-card7-imgs">
                                <a href="app-details.html" />
                                <img
                                  src={`${config.apiUrl}${item.image}`}
                                  alt="Dash"
                                  className="  p-0"
                                />
                              </div>
                              <div className="card-body">
                                <div className="item-card7-desc">
                                  <div className="item-card7-text">
                                    <a
                                      href="app-details.html"
                                      className="text-dark text-header"
                                    >
                                      <h4 className="mb-1">{item.title}</h4>
                                    </a>
                                    <a
                                      href="app-details.html"
                                      className="text-subtext"
                                    >
                                      Free
                                    </a>
                                  </div>
                                  <ul className="item-cards7-ic software-list mb-0 mt-3">
                                    <li>
                                      <a
                                        href="google.com;"
                                        className="icons text-muted "
                                      >
                                        <i className="fa fa-database text-muted me-1" />{" "}
                                        {item.size} MB
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="google.com;"
                                        className="icons text-muted "
                                      >
                                        <i className="fa fa-download text-muted me-1" />{" "}
                                        {item.visit_count} visits
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="google.com;"
                                        className="icons text-muted "
                                      >
                                        <i className="fa fa-refresh text-muted me-1" />{" "}
                                        20.0.4 v{" "}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="google.com;"
                                        className="icons text-muted "
                                      >
                                        <i className="fa fa-comment-o text-muted me-1" />{" "}
                                        {item.comments_count} Comments
                                      </a>
                                    </li>
                                  </ul>
                                  <p className="mb-0">{item.description.slice(0, 150)} <strong>...</strong> </p>
                                </div>
                              </div>
                              <div className="card-footer">
                                <div className=" row">
                                  <div className="col-7 pe-0 mb-0 text-start">
                                    <div className="d-inline-flex flex-wrap">
                                      <div
                                        className="rating-star sm my-rating-5"
                                        data-stars="4s"
                                      ></div>{" "}
                                      ({item.average_rating}/5)
                                    </div>
                                  </div>
                                  <div className="col-5 text-end">
                                    <a href="google.com;" title="Categorie">
                                      {/* <i className="mdi mdi-android text-muted me-1" /> */}
                                      {item.category_name}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/products" className="btn btn-primary">
                View more ..
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopApps;
