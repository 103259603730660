import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import APIService from "../Data/APIService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken, removeCookie] = useCookies(["mytoken"]);
  let navigate = useNavigate();
  const [isLogin, setLogin] = useState(true);
  const [errors, setErrors] = useState({});

  /*  useEffect(() => {
    var user_token = token["mytoken"];
    console.log("Login User token is", user_token);
    console.log("Data type", typeof token["mytoken"]);

    if (String(user_token) === "undefined") {
      navigate("/");
    } else {
      navigate("/BlogTest");
    }
  }, [token]);
 */
  const loginBtn = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (username.trim().length === 0) {
      newErrors.username = "Username is required";
    }

    if (password.trim().length === 0) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      // Display validation errors in toasts
      Object.values(newErrors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
      setErrors(newErrors);
      return;
    }

    if (username.trim().length !== 0 && password.trim().length) {
      console.log("Username And Password Are Set");
      APIService.LoginUser({ username, password })
        .then((resp) => {
          if (resp.api_token && resp.user) {
            localStorage.setItem("token", resp.api_token);
            localStorage.setItem("user", resp.user.id);
            localStorage.setItem("username", resp.user.username);
            localStorage.setItem("user_data", JSON.stringify(resp.user));
            console.log(resp.user);
            navigate("/");
          } else {
            toast("Login failed:");
            // Handle login failure, show error message, etc.
          }
        })
        .catch((error) => {
          console.error("An error occurred during login:", error);
          // Handle network or other errors, show error message, etc.
        });
    } else {
      console.log("Username And Password Are Not Set");
      navigate("/");
    }
  };

  return (
    <section className="sptb">
      <div className="container customerpage">
        <div className="row">
          <div className="single-page">
            <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
              <div className="wrapper wrapper2">
                <form id="login" className="card-body" tabIndex={500}>
                  <h3>Login</h3>
                  <div className="mail">
                    <input
                      type="text"
                      value={username}
                      className="form-control"
                      placeholder="Enter Username"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <label> Username</label>
                  </div>
                  <div className="passwd">
                    <input
                      type="password"
                      value={password}
                      className="form-control"
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label>Password</label>
                  </div>
                  <div className="submit">
                    <button
                      className="btn btn-primary btn-block"
                      onClick={loginBtn}
                    >
                      Login
                    </button>
                  </div>
                  <p className="mb-2">
                    <a href="forgot.html">Forgot Password</a>
                  </p>
                  <p className="text-dark mb-0">
                    Don't have account?
                    <Link
                      to="/register"
                      className="text-primary ms-1 d-inline-block"
                    >
                      Sign UP
                    </Link>
                  </p>
                </form>
     
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
