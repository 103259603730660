import React from 'react'
import { Link } from 'react-router-dom'

function Breadcrumb(props) {
  return (
<section>
  <div className="bannerimg cover-image background-size bg-background3" data-bs-image-src="../assets/images/banners/banner2.jpg">
    <div className="header-text mb-0">
      <div className="container">
        <div className="text-center text-white ">
          <h1 >{props.data.title}</h1>
          <ol className="breadcrumb text-center">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><Link to={props.data.parentPath}>{props.data.Parent}</Link></li>
            <li className="breadcrumb-item active text-white" aria-current="page"><Link href={props.data.pagePath}>{props.data.pageLink}</Link></li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Breadcrumb
