  import logo from "./logo.svg";
  import "./App.css";
  import Landing from "../src/pages/Landing";
  import Blog from "../src/pages/Blog";
  import { Routes, Route } from "react-router-dom";
  import ContactUs from "../src/pages/Contact-Us";
  import BlogdetailP from "./pages/Blog-detailP";
  import Categoriesp from "./pages/Categories-p";
  import ProductsP from "./pages/ProductsP";
  import BlogTest from "./pages/BlogTest";
  import PostP from "./component/Post/ArticlePost";
  import { useCookies } from "react-cookie";
  import Login from "./pages/Login";
  import Register from "./pages/Register";
  import BlogPost from "./pages/BlogPost";
  import ArticlePostP from "./pages/ArticlePostDetailP";
  import ArticlePostSlug from "./pages/ArticlePostDetailPbyslug";
  import Dashboard from "./pages/Dashboard";
  import TestModal from "./pages/TestModal";
  import Appupload from "./pages/App_upload";
  import Header from "./component/header/Header";
import Appdetail from "./pages/App_detailById";
import About from "./pages/About";

  function App() {
    const [token, setToken, removeToken] = useCookies(["mytoken"]);

    return (
      <>
        <Routes>
          {<Route path="/" element={<Landing />} />}
          <Route path="/blogs" element={<BlogTest />} />
          <Route path="/contact-us" element={<ContactUs />} />
          
          <Route path="/Blogs/Blog-detail" element={<BlogdetailP />} />

          <Route path="/Categories" element={<Categoriesp />} />
          <Route path="/products" element={<ProductsP />} />
          <Route path="/products/:catid" element={<ProductsP />} />
          <Route path="/products/:appname/:catid" element={<ProductsP />} />
          <Route path="/login" element={<Login />} />

          <Route path="/blog/create-new-blog" element={<BlogPost />} />
          <Route path="/register" element={<Register />} />
          <Route path="/blogs/blog-detail/:id/" element={<ArticlePostP />} />
          <Route path="/blog/:slug" element={<ArticlePostSlug/>} />
          <Route path="/TestModal" element={<TestModal />} />
          <Route path="/user-dashboard" element={<Dashboard />} />
          <Route path="/products/upload-new-product" element={<Appupload />} />
          <Route path="/products/App-detail/:id" element={<Appdetail />} />
          <Route path="/aboutus" element={<About />} />
        </Routes>
      </>
    );
  }

  export default App;
