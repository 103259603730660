import React from 'react'
import Ratings from 'react-rating-stars-component';

function Rating({ value, onRatingChange }) {
    return (
        <Ratings
            value={value}
            count={5} 
            onChange={onRatingChange}
            size={24} 
            activeColor="#ffd700" 
            inactiveColor="#ddd" 
        />
    );
}

export default Rating
