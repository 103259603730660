import React,{useEffect,useState} from 'react'
import APISerive from '../Data/APIService';
import MyModal from '../hero/MyModal';
function Reviews() {
  
  const [numers, setNumbers] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    APISerive.get_all_numbers_home()
      .then((resp) => {setNumbers(resp);console.log(resp)})
      .catch((error) => {
        console.error("Error fetching blog:", error);
      });
  }, []);

  return (
    <div>
        {/*Statistics*/}
        <section>
          <div
            className="about-1 cover-image sptb background-size pt-7 pb-7 bg-background-color"
            data-bs-image-src="../assets/images/banners/banner1.jpg"
          >
            <div className="content-text mb-0 text-white info">
              <div className="container">
                <div className="row text-center">
                  <div className="col-lg-4 col-md-4 mt-3 mb-3">
                    <i className="fa fa-comment-o fs-25 counter-status-icon me-3 mb-1" />
                    <div className="counter-status md-mb-0">
                      <h2 className="counter  mb-3">{numers.comments}</h2>
                      <h5>Application Reviews</h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mt-3 mb-3">
                    <i className="fa fa-download counter-status-icon fs-25 me-3 mb-1" />
                    <div className="counter-status md-mb-0">
                      <h2 className="counter  mb-3">{numers.totalblog}</h2>
                      <h5>Blog posted</h5>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mt-3 mb-3">
                    <i className="fa fa-refresh  fs-25 counter-status-icon me-3 mb-1" />
                    <div className="counter-status">
                      <h2 className="counter mb-3">{numers.totalvisits}</h2>
                      <h5>Application are  Visited</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
        </section>


        {/*/Statistics*/}
    </div>
  )
}

export default Reviews
