import React, { useState, useRef } from "react";
import APIService from "../Data/APIService";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { toHaveDisplayValue } from "@testing-library/jest-dom/matchers";
import $ from 'jquery';

function Contactus() {
  const [capVal, setCapVal] = useState(null);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const recaptchaRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      $("#global-loader").fadeIn("slow");
      APIService.InsertContactUs({
        username: username,
        message: message,
        email: email,
      }).then((res) => {
        setEmail("");
        setMessage("");
        setUsername("");
        //setCapVal("");
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }

        toast.success("Your message is send , we will get back to you soon !", {
          position: toast.POSITION.TOP_CENTER,
        });

      }).finally($("#global-loader").fadeOut("slow"));
    } else {
      // Form validation failed, display error messages
      setErrors(errors);
      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!username.trim()) {
      errors.username = "Name is required";
    }

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    if (!message.trim()) {
      errors.message = "Message is required";
    }

    return errors;
  };

  return (
    <div className="sptb ">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xl-4  col-md-12  d-block mb-7">
            <div className="section-title center-block text-start">
              <h2>Contact Info</h2>
            </div>
            <div className="row text-white">
              <div className="col-12 mb-5">
                <div className="support-service bg-primary mb-4 mb-xl-0">
                  <i className="fa fa-phone" />
                  <h6>+68 872-627-9735</h6>
                  <p>Free Support!</p>
                </div>
              </div>
              <div className="col-12 mb-5">
                <div className="support-service bg-secondary mb-4 mb-xl-0">
                  <i className="fa fa-clock-o" />
                  <h6>Mon-Sat(10:00-19:00)</h6>
                  <p>Working Hours!</p>
                </div>
              </div>
              <div className="col-12">
                <div className="support-service bg-warning">
                  <i className="fa fa-envelope-o" />
                  <h6>yourdomain@gmail.com</h6>
                  <p>Support us!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-xl-8 col-md-12 d-block ">
            <div className="single-page">
              <div className="col-lg-12  col-md-12 mx-auto d-block">
                <div className="section-title center-block text-start">
                  <h2>Contact Form</h2>
                </div>
                <form onSubmit={handleSubmit} method="POST">
                  <div className="wrapper wrapper2">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            placeholder="Your Name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email Address"
                          />
                        </div>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            name="message"
                            onChange={(e) => setMessage(e.target.value)}
                            required
                            rows={6}
                            value={message}
                            placeholder="Message"
                            defaultValue={""}
                          />
                          {/* <div class="g-recaptcha" data-sitekey="6Le5hlEoAAAAAPqZFLZN2whNhGN9bYOUkjO-x_Fc"></div> */}

                          <br />
                          {/* 6Le5hlEoAAAAAPqZFLZN2whNhGN9bYOUkjO-x_Fc */}
                          <ReCAPTCHA
                            sitekey="6LfR_psoAAAAALHxbCH-lBSYVgGocFSzf8-Cj9IW"
                            onChange={(val) => {
                              setCapVal(val);
                            }}
                          ></ReCAPTCHA>
                          <br />
                        </div>
                        <button
                          className="btn btn-primary float-start"
                          disabled={!capVal}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
