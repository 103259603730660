import React ,{useState}from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
function NewsLetter() {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      APISerive.Insert_subscribe({
        email: email,
      }).then((res) => {
        setEmail("");
        toast.success("You are subscribed  !", {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    } else {
      // Form validation failed, display error messages

      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };
  return (
    <section className="sptb bg-white border-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xl-6 col-md-12">
            <div className="sub-newsletter">
              <h3 className="mb-2">
                <i className="fa fa-paper-plane-o me-2" /> Subscribe To Our
                Newsletter
              </h3>
              <p className="mb-0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </p>
            </div>
          </div>
         
            <div className="col-lg-5 col-xl-6 col-md-12 mt-xl-0 mt-4">
            <form onSubmit={handleSubmit} method="POST">
              <div className="input-group sub-input mt-1">
              
                <input
                  type="email"
                  className="form-control input-lg "
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email Address"
                />
                <button
                  type="submit"
                  className="btn btn-primary btn-lg br-te-3  br-be-3"
                >
                  Subscribe
                </button>
               
              </div>
              </form>
            </div>
      
        </div>
      </div>
    </section>
  );
}

export default NewsLetter;
