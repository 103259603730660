import React, { useState } from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StarRating from "../starRating/Rating";

function CommentForm(props) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [errors, setErrors] = useState({});
  console.log(props.article.data.id);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const insertComment = (e) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
     
      if (props.article.data.id != null) {
        const formData = new FormData();
        formData.append("user", localStorage.getItem("user"));
        formData.append("article", props.article.data.id);
        formData.append("username", "empty");
        //formData.append("email", email);
        formData.append("message", message);
        formData.append("rating", rating);

        APISerive.InsertComment(formData, localStorage.getItem("token"))
          .then((resp) => {
            toast.success("Your comment submitted successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });

            setEmail("");
            setUsername("");
            setMessage("");
            handleRatingChange("0");
            APISerive.getFeedback(props.article.data.id)
              .then((res) => {
                props.reloadFeedbacks(res); // Call the parent's updateArticles function
              })
              .catch((error) => {
                console.error("Error fetching updated articles:", error);
              });
          })
          .catch((error) => {
            toast.error("You have posted your comment earlier!", {
              position: toast.POSITION.TOP_CENTER,
            });
            console.error("Error deleting blog:", error.message);
          });
      }
    } else {
      setErrors(newErrors);

      // Display each error message in a toast
      Object.values(newErrors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};

    if (!message.trim()) {
      newErrors.title = "Your comment is required";
    }

    if (!rating) {
      newErrors.description = "rating is required";
    }

    if (!localStorage.getItem("user")) {
      newErrors.description = "You are not logined";
    }

    return newErrors;
  };
  return (
    <div className="card mb-lg-0">
      <div className="card-header">
        <h3 className="card-title">Write Your Comments</h3>
      </div>
      <form id="saveComment" className="card-body" tabIndex={500}>
        <div className="card-body">
          {/*    <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Your Name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
  </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email Course address"
            />
          </div> */}
          <div className="form-group">
            <textarea
              className="form-control"
              name="example-textarea-input"
              rows={4}
              placeholder="Write Your Comment"
              defaultValue={""}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p className="m-0 me-2">
              <strong>Please select a rating for this blog</strong>
            </p>
            <StarRating value={rating} onRatingChange={handleRatingChange} />
          </div>
          
          <button

            className="btn btn-primary btn-block mt-3"
            onClick={insertComment}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default CommentForm;
