import React from 'react'
import Breadcrumb from "../component/breadcrumb/Breadcrumb";

import BlogDetail from "../component/blogdetail/blogDetail";

import Footer from "../component/footer/footer";
function BlogdetailP() {
  const dataTosend = { title: "Blog Details" };
  return (
    <div>
    <Breadcrumb data={dataTosend} />
      <BlogDetail/>
      <Footer/>
    </div>
  )
}

export default BlogdetailP
