import React ,{useEffect} from 'react'
import RegisterP from "../component/register/Register";
import Footer from "../component/footer/footer";
import Headers from "../component/header/Header";
import $ from 'jquery'; 

function Register() {
  useEffect(() => {
    document.title = 'AIConsumer.Register';
    $("#global-loader").fadeOut("slow")
    // This code will run after the component has mounted
    $(document).ready(function() {
      $(window).on("load", function(e) {
        $("#global-loader").fadeOut("slow");
      });
    });

    // Be sure to clean up any event listeners or resources in the return function
    return () => {
      $(window).off("load"); // Remove the event listener when the component unmounts
    };
  }, []); // The empty dependency array means this effect runs only once after mounting

  return (
    <div>
    <Headers/>
    <RegisterP/>
    <Footer/>
  </div>
  )
}

export default Register
