import React, { useEffect } from "react";
import Header from "../component/header/Header";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import AppUploadfrm from "../component/Apps/AppUploadfrm";
import $ from "jquery";
import { Link } from "react-router-dom";

function App_upload() {
  useEffect(() => {
    $("#global-loader").fadeOut("slow");
  }, []);

  const dataTosend = { title: "UPload your Apps" };
  return (
    <div>
      <Header />
      <section>
        <div
          className="bannerimg cover-image background-size bg-background3"
          data-bs-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white ">
                <h1>Upoad your new product</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/user-dashboard">Dashboard</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    Upload Product
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <AppUploadfrm />
    </div>
  );
}

export default App_upload;
