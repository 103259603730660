import React,{useEffect} from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import Hero from "../component/hero/hero";
import Slider from "../component/slider/slider";
import Subscriber from "../component/subscriber/subscriber";
import TopApps from "../component/TopApps/topApps";
import Reviews from "../component/reviews/reviews";
import Testimonails from "../component/testimonials/testimonials"
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import $ from 'jquery'; 
import { owlCarousal } from "../utilities/ow";
function Landing() {

  useEffect(() => {
    document.title = 'AIConsumer.Home';
    
    $("#global-loader").fadeOut("slow")
    //owlCarousal()
  }, []);

  return (
    <div>
      <Header/>
      <Hero />
      <Slider />
      <Subscriber />
      <TopApps />
      <Reviews/>
      <Testimonails/>
      <Footer />
    </div>
  );
}

export default Landing;
