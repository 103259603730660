import React, { useEffect, useState } from "react";
import Breadcrumb from "../component/breadcrumb/Breadcrumb";
import ArticleDetail from "../component/Post/ArticleDetail";
import Header from "../component/header/Header";
import Footer from "../component/footer/footer";
import CommentForm from "../component/comment/CommentForm";
import Feedbacks from "../component/comment/Feedbacks";
import { useParams ,useLocation } from "react-router-dom";
import APISerive from "../component/Data/APIService";
import { Link } from "react-router-dom";
import $ from 'jquery';
function ArticlePostDetailP(props) {

  const dataTosend = { title: "Article Detail" };
  const { id } = useParams();
  const location = useLocation();

  const [article, setArticle] = useState(null);
  const [feedbacks, setFeedbacks] = useState(null);

  const reloadFeedbacks = (newFeedbacks) => {
    setFeedbacks(newFeedbacks);
  };


  const customPropValue = location.state?.customProp || '';
  console.log("values are")
  console.log(customPropValue)
  console.log(location.state)

  useEffect(() => {
    
    $("#global-loader").fadeOut("slow");

    const fetchData = async () => {
      try {
        const user_token = localStorage.getItem("token");
        console.log(id);

        // Fetch article data
        const article = await APISerive.getArticle(id);
        setArticle(article);

        // Fetch feedback data
        const feedbacks = await APISerive.getFeedback(id);
        setFeedbacks(feedbacks);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log(article);
    console.log(feedbacks); // This will log the updated 'article' state
  }, [article, feedbacks]);

  return (
    <div>
      <Header />
      <Breadcrumb data={dataTosend} />
      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-12">
              {article ? (
                <>
                  <ArticleDetail article={article} />
                  {feedbacks ? (
                    <Feedbacks feedbacks={feedbacks} />
                  ) : (
                    <p>Loading feedback...</p>
                  )}
                  <CommentForm
                    article={article}
                    reloadFeedbacks={reloadFeedbacks}
                  />
                </>
              ) : (
                <p>Loading article...</p>
              )}
            </div>
            {/*Rightside Content*/}
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                    />
                    <button type="button" className="btn btn-primary">
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Blog Categories</h3>
                </div>
                <div className="card-body p-2">
                  <div className="list-catergory">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        <li className="list-group-item">
                          <a
                            href="javascript:void(0);"
                            className="text-default-dark"
                          >
                            <i className="fe fe-chevrons-right" /> Business
                            <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                              14 Posts
                            </span>
                          </a>
                        </li>
                        <li className="list-group-item">
                          <a
                            href="javascript:void(0);"
                            className="text-default-dark"
                          >
                            <i className="fe fe-chevrons-right" /> IT Services
                            <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                              74 Posts
                            </span>
                          </a>
                        </li>
                        <li className="list-group-item">
                          <a
                            href="javascript:void(0);"
                            className="text-default-dark"
                          >
                            <i className="fe fe-chevrons-right" /> Research
                            <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                              18 Posts
                            </span>
                          </a>
                        </li>
                        <li className="list-group-item">
                          <a
                            href="javascript:void(0);"
                            className="text-default-dark"
                          >
                            <i className="fe fe-chevrons-right" /> Mobile
                            computing
                            <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                              32 Posts
                            </span>
                          </a>
                        </li>
                        <li className="list-group-item border-bottom-0 br-bs-7 br-be-7">
                          <a
                            href="javascript:void(0);"
                            className="text-default-dark"
                          >
                            <i className="fe fe-chevrons-right" /> Listing
                            Applications
                            <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">
                              08 Posts
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Latest Posts</h3>
                </div>
                <div className="card-body p-3">
                  <div className="list-catergory1">
                    <div className="item-list">
                      <ul className="list-group mb-0">
                        <li className="list-group-item d-flex border-bottom-0">
                          <img
                            src="../assets/images/photos/11.jpg"
                            className="avatar br-5 avatar-xl me-3 my-auto"
                            alt="avatar-img"
                          />
                          <div className>
                            <small className="d-block">Business</small>
                            <a className="text-default font-weight-semibold">
                              Excepteur occaecat cupidatat
                            </a>
                            <small className="d-block text-muted">
                              1 day ago
                            </small>
                          </div>
                        </li>
                        <li className="list-group-item d-flex border-bottom-0">
                          <img
                            src="../assets/images/photos/12.jpg"
                            className="avatar br-5 avatar-xl me-3 my-auto"
                            alt="avatar-img"
                          />
                          <div className>
                            <small className="d-block">Market Place</small>
                            <a className="text-default font-weight-semibold">
                              Excepteur occaecat cupidatat
                            </a>
                            <small className="d-block text-muted">
                              1 day ago
                            </small>
                          </div>
                        </li>
                        <li className="list-group-item d-flex border-bottom-0">
                          <img
                            src="../assets/images/photos/13.jpg"
                            className="avatar br-5 avatar-xl me-3 my-auto"
                            alt="avatar-img"
                          />
                          <div className>
                            <small className="d-block">Mobile Computing</small>
                            <a className="text-default font-weight-semibold">
                              Excepteur occaecat cupidatat
                            </a>
                            <small className="d-block text-muted">
                              1 day ago
                            </small>
                          </div>
                        </li>
                        <li className="list-group-item d-flex border-bottom-0">
                          <img
                            src="../assets/images/photos/14.jpg"
                            className="avatar br-5 avatar-xl me-3 my-auto"
                            alt="avatar-img"
                          />
                          <div className>
                            <small className="d-block">App Developing</small>
                            <a className="text-default font-weight-semibold">
                              Excepteur occaecat cupidatat
                            </a>
                            <small className="d-block text-muted">
                              1 day ago
                            </small>
                          </div>
                        </li>
                        <li className="list-group-item d-flex border-bottom-0 br-bs-7 br-be-7">
                          <img
                            src="../assets/images/photos/15.jpg"
                            className="avatar br-5 avatar-xl me-3 my-auto"
                            alt="avatar-img"
                          />
                          <div className>
                            <small className="d-block">Networking</small>
                            <a className="text-default font-weight-semibold">
                              Excepteur occaecat cupidatat
                            </a>
                            <small className="d-block text-muted">
                              1 day ago
                            </small>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*/Rightside Content*/}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ArticlePostDetailP;
