import React from 'react'

function categories() {
  return (
    <div>
<div>
  {/*Categories*/}
  <section className="sptb bg-white">
    <div className="container">
      <div className="section-title center-block text-center">
        <h2>Categories</h2>
        <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
      </div>
      <div className="row text-center justify-content-center">
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
          <div className="card bg-card">
            <div className="card-body">
              <div className="cat-item text-center">
                <a href="classifieds-list.html" />
                <div className="cat-img ">
                  <i className="fa fa-android fa-2x " />
                </div>
                <div className="cat-desc">
                  <h5 className="mb-1">Android</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
          <div className="card bg-card">
            <div className="card-body">
              <div className="cat-item text-center">
                <a href="classifieds-list.html" />
                <div className="cat-img ">
                  <i className="fa fa-windows fa-2x text-primary-gradient" />
                </div>
                <div className="cat-desc">
                  <h5 className="mb-1">Windows</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
          <div className="card bg-card">
            <div className="card-body">
              <div className="cat-item text-center">
                <a href="classifieds-list.html" />
                <div className="cat-img ">
                  <i className="fa fa-apple fa-2x text-primary-gradient " />
                </div>
                <div className="cat-desc">
                  <h5 className="mb-1">Mac</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-lg-2 col-md-4 col-sm-6">
          <div className="card bg-card">
            <div className="card-body">
              <div className="cat-item text-center">
                <a href="classifieds-list.html" />
                <div className="cat-img ">
                  <i className="fa fa-briefcase fa-2x text-primary-gradient" />
                </div>
                <div className="cat-desc">
                  <h5 className="mb-1">Business Apps</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/Categories*/}
  {/*Categories*/}
  <section className="sptb">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card mb-0">
            <div className="card-header">
              <h3 className="card-title">Categories</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-5">
                  <div className="card-body border rounded">
                    <div className="cat-title">
                      <a href="javascript:void(0);" className="text-dark"><h4 className="mb-3"><i className="fa fa-android text-primary icon-bg" />  Android</h4></a>
                    </div>
                    <ul className="list-unstyled widget-spec  p-1">
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Art &amp; Design</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Auto &amp; Vehicles</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Beauty</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Books &amp; Reference</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" />Comics</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Education</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Entertainment</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Finance</a>
                      </li>
                      <li className="mb-0 justify-content-end">
                        <a href="javascript:void(0);" className="btn-sm btn btn-primary"> View more <i className="fe fe-arrow-right me-0" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                  <div className="card-body border rounded">
                    <div className="cat-title">
                      <a href="javascript:void(0);" className="text-dark"><h4 className="mb-3"><i className="fa fa-windows text-primary icon-bg" />  Windows</h4></a>
                    </div>
                    <ul className="list-unstyled widget-spec p-1">
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Art &amp; Design</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Auto &amp; Vehicles</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Beauty</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Books &amp; Reference</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" />Comics</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Education</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Entertainment</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Finance</a>
                      </li>
                      <li className="mb-0 justify-content-end">
                        <a href="javascript:void(0);" className="btn-sm btn btn-primary"> View more <i className="fe fe-arrow-right me-0" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                  <div className="card-body border rounded">
                    <div className="cat-title">
                      <a href="javascript:void(0);" className="text-dark"><h4 className="mb-3"><i className="fa fa-apple text-primary icon-bg" />  Mac</h4></a>
                    </div>
                    <ul className="list-unstyled widget-spec p-1">
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Art &amp; Design</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Auto &amp; Vehicles</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Beauty</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Books &amp; Reference</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" />Comics</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Education</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Entertainment</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Finance</a>
                      </li>
                      <li className="mb-0 justify-content-end">
                        <a href="javascript:void(0);" className="btn-sm btn btn-primary"> View more <i className="fe fe-arrow-right me-0" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                  <div className="card-body border rounded">
                    <div className="cat-title">
                      <a href="javascript:void(0);" className="text-dark"><h4 className="mb-3"><i className="fa fa-briefcase text-primary icon-bg" />  Businessapp</h4></a>
                    </div>
                    <ul className="list-unstyled widget-spec p-1">
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Art &amp; Design</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Auto &amp; Vehicles</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Beauty</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Books &amp; Reference</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" />Comics</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Education</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Entertainment</a>
                      </li>
                      <li className>
                        <a href="javascript:void(0);" className="text-dark"><i className="fe fe-arrow-right-circle text-primary" /> Finance</a>
                      </li>
                      <li className="mb-0 justify-content-end">
                        <a href="javascript:void(0);" className="btn-sm btn btn-primary"> View more <i className="fe fe-arrow-right me-0" /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/*/Categories*/}
</div>

    </div>
  )
}

export default categories
