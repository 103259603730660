import React  ,{useState} from "react";
import APISerive from "../Data/APIService";
import { toast } from "react-toastify";
import $ from 'jquery';

function Subscriber() {
  const [email, setEmail] = useState("");

 const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    
    
    if (Object.keys(errors).length === 0) {
      try {
        $("#global-loader").fadeIn("slow");
    
        // Make the API call using async/await
        const response = await APISerive.Insert_subscribe({
          email: email,
        });
    
        // Handle the response
        setEmail("");
        toast.success("You are subscribed!", {
          position: toast.POSITION.TOP_CENTER,
        });
      } catch (error) {
        console.error("Error subscribing:", error);
        // Handle the error if necessary
      } finally {
        $("#global-loader").fadeOut("slow");
      }
    } else {
      // Form validation failed, display error messages
  
      Object.values(errors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  
  return (
    <div>
      {/* Newsletter*/}
      <section>
        <div
          className="about-1 cover-image background-size sptb-2 bg-background-5 "
          data-bs-image-src="../assets/images/banners/banner4.jpg"
        >
          <div className="container">
            <div >
            <form onSubmit={handleSubmit} method="POST">
              <div className="col-lg-12 col-xl-12 col-md-12">
                <div className="sub-newsletter text-white  text-center">
                  <h2 className="mb-3">
                    <i className="fa fa-paper-plane-o me-2" /> Subscribe To get
                    Latest Updates
                  </h2>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="input-group text-center sub-input mt-1 w-60 ms-auto me-auto mt-6">
                    <input
                      type="email"
                      className="form-control input-lg "
                      name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            placeholder="Email Address"
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg br-te-3  br-be-3"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
            </form>
            </div>
          </div>
        </div>
      </section>
      {/*/Newsletter*/}
    </div>
  );
}

export default Subscriber;
