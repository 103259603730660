import React, { useState, useEffect } from "react";
import APISerive from "../Data/APIService";
import { useCookies } from "react-cookie";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
function ArticlePost() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [intro, setintro] = useState("");
  const [token] = useCookies(["mytoken"]);
  const [category, setcategory] = useState([""]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [imagefile, setImagefile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleContentChange = (value) => {
    setDescription(value);
  };
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagefile(file);
  };

  useEffect(() => {
    APISerive.BlogCategories(localStorage.getItem("token")).then(
      (reponseData) => {
        setcategory(reponseData);
      }
    );
  }, []);

  const insertArticle = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      console.log(token["mytoken"]);
      /* APISerive.getUserId(token["mytoken"]).then((resp)=>
        console.log("User Id is "+resp)
      ); */
      console.log(imagefile);
      const formData = new FormData();
      formData.append("title", title);
      //formData.append("slug", title);
      formData.append("intro", intro);
      formData.append("description", description);
      formData.append("category", selectedCategory);
      formData.append("image", imagefile);
      formData.append("user", localStorage.getItem("user"));

      APISerive.InsertArticle(formData, localStorage.getItem("token")).then(
        toast.success("Your Blog is submitted successfully!", {
          position: toast.POSITION.TOP_CENTER,
        })
      );

      setTitle("");
      setDescription("");
      setintro("");
      setSelectedCategory("");
      setImagefile(null);
    } else {
      // Form validation failed, display error messages in toasts
      setErrors(newErrors);

      // Display each error message in a toast
      Object.values(newErrors).forEach((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!title.trim()) {
      newErrors.title = "Title is required";
    }

    if (!selectedCategory) {
      newErrors.category = "Category is required";
    }

    if (!imagefile) {
      newErrors.image = "Image is required";
    }

    if (!intro.trim()) {
      newErrors.intro = "Introduction is required";
    }

    if (!description.trim()) {
      newErrors.description = "Description is required";
    }

    return newErrors;
  };

  return (
    <section className="sptb">
      <div className="container">
        <div className="row">
          <div className="single-page">
            <div className="col-lg-12 col-xl-12 col-md-12  mx-auto">
              <div className="card">
                <div className="card-body">
                  <form
                    id="Register"
                    tabIndex={500}
                    encType="multipart/form-data"
                  >
                    <div className="form-group">
                      <label className="form-label fw-semibold text-dark">
                        Ad Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group mb-5">
                      <label className="form-label text-dark fw-semibold">
                        Select Categories
                      </label>
                      <select
                        className="form-control"
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                      >
                        <option value="">Select an option</option>
                        {category.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="form-label fw-semibold text-dark">
                        Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="form-group">
                    <label className="form-label fw-semibold text-dark">
                      Introduction
                    </label>
                      <input
                        type="text"
                        className="form-control"
                        name="intro"
                        value={intro}
                        onChange={(e) => setintro(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                    <label className="form-label fw-semibold text-dark">
                      Description
                    </label>
                      <ReactQuill
                        className="col-lg-12 col-xl-12 col-md-12 d-block mx-auto"
                        style={{
                          height: "300px",
                          color: "black",
                          paddingInlineEnd: "0rem",
                          paddingInlineStart: "0rem",
                        }}
                        value={description}
                        onChange={handleContentChange}
                      />
                    </div>
                    <br />
                    <div
                      className="submit mt-6"
                      style={{ marginBlockStart: "4rem", marginTop: "3rem" }}
                    >
                      <button
                        className="btn btn-primary  mt-3"
                        onClick={insertArticle}
                      >
                        Upload Blog
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ArticlePost;
