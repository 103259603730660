import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
import logo from "../../logo.png";
import loader from "../../loader.gif";
function Header() {
  const [token, setToken, removeToken] = useCookies(["mytoken"]);
  const [effectHasRun, setEffectHasRun] = useState(false);
  const [showLinkLogin, setShowLinkLogin] = useState(false);
  const [showLinkRegister, setShowLinkRegister] = useState(false);
  const [showDash, setShowDash] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    console.log(effectHasRun);
    var user_token = localStorage.getItem("token");
    console.log(String(user_token));
    if (!effectHasRun) {
      setEffectHasRun(true);

      var username = localStorage.getItem("username");

      if (String(user_token) === "null") {
        console.log("inside");
        const ul = document.getElementsByClassName("lnks4loginReg")[0];
        console.log(ul);
        setShowLinkLogin(true);
        setShowLinkRegister(true);
        setShowDash(false);
      } else {
        setShowLinkLogin(false);
        setShowLinkRegister(false);
        setShowDash(true);
      }
    }
    if (String(user_token) === "null") {
      console.log("inside");
      const ul = document.getElementsByClassName("lnks4loginReg")[0];
      console.log(ul);
      setShowLinkLogin(true);
      setShowLinkRegister(true);
      setShowDash(false);
    } else {
      setShowLinkLogin(false);
      setShowLinkRegister(false);
      setShowDash(true);
    }
  }, [token, effectHasRun]);

  const LogoutBtn = () => {
    removeToken(["mytoken"]);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // setToken('')
    setShowLinkLogin(true);
    setShowLinkRegister(true);
    setShowDash(false);
    navigate("/");
  };
  return (
    <div>
      {/*Loader*/}
      <div id="global-loader">
        <img
          src={loader}
          className="loader-img "
          alt="sdsd"
        />
      </div>
      {/*Topbar*/}
      <div className="header-main header-style3">
        <div className="top-bar ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-8 col-sm-4 col-7">
                <div className="top-bar-left d-flex align-items-center">
                  <div className="clearfix">
                    <ul className="socials">
                      <li>
                        <a className="social-icon text-dark" href="google.com;">
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                      <li>
                        <a className="social-icon text-dark" href="google.com;">
                          <i className="fa fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a className="social-icon text-dark" href="google.com;">
                          <i className="fa fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a className="social-icon text-dark" href="google.com;">
                          <i className="fa fa-google-plus" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="clearfix">
                    <ul className="contact border-start">
                      <li className="me-5 d-lg-none">
                        <a href="google.com;" className="callnumber text-dark">
                          <span>
                            <i className="fa fa-phone me-1" />: +425 345 8765
                          </span>
                        </a>
                      </li>
                      {/*  <li className="select-country me-1">
                        <select
                          className="form-control select2-flag-search"
                          data-placeholder="Select Country"
                        >
                          <option value={1}>United States</option>
                          <option value={2}>Germany</option>
                          <option value={3}>Italy</option>
                          <option value={4}>Russia</option>
                          <option value={5}>Spain</option>
                        </select>
  </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-sm-8 col-5">
                <div className="top-bar-right">
                  <ul className="custom lnks4loginReg">
                    {showLinkLogin && (
                      <li>
                        <Link to="/login" className="text-dark cntrls">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="15px"
                            height="15px"
                            className="login-details"
                          >
                            <path
                              fill="currentColor"
                              d="M344 240h-56L287.1 152c0-13.25-10.75-24-24-24h-16C234.7 128 223.1 138.8 223.1 152L224 240h-56c-9.531 0-18.16 5.656-22 14.38C142.2 263.1 143.9 273.3 150.4 280.3l88.75 96C243.7 381.2 250.1 384 256.8 384c7.781-.3125 13.25-2.875 17.75-7.844l87.25-96c6.406-7.031 8.031-17.19 4.188-25.88S353.5 240 344 240zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464z"
                            />
                          </svg>
                          <span>Login</span>
                        </Link>{" "}
                      </li>
                    )}
                    {showLinkRegister && (
                      <li>
                        <Link to="/register" class="text-dark cntrls">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="15px"
                            height="15px"
                            class="login-details"
                          >
                            <path
                              fill="currentColor"
                              d="M256 112c-48.6 0-88 39.4-88 88C168 248.6 207.4 288 256 288s88-39.4 88-88C344 151.4 304.6 112 256 112zM256 240c-22.06 0-40-17.95-40-40C216 177.9 233.9 160 256 160s40 17.94 40 40C296 222.1 278.1 240 256 240zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-46.73 0-89.76-15.68-124.5-41.79C148.8 389 182.4 368 220.2 368h71.69c37.75 0 71.31 21.01 88.68 54.21C345.8 448.3 302.7 464 256 464zM416.2 388.5C389.2 346.3 343.2 320 291.8 320H220.2c-51.36 0-97.35 26.25-124.4 68.48C65.96 352.5 48 306.3 48 256c0-114.7 93.31-208 208-208s208 93.31 208 208C464 306.3 446 352.5 416.2 388.5z"
                            ></path>
                          </svg>
                          <span>Register</span>
                        </Link>
                      </li>
                    )}
                    {showDash && (
                      <li>
                        <a
                          onClick={LogoutBtn}
                          href="#"
                          class="text-dark cntrls"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="15px"
                            height="15px"
                            class="login-details"
                          >
                            <path
                              fill="currentColor"
                              d="M256 112c-48.6 0-88 39.4-88 88C168 248.6 207.4 288 256 288s88-39.4 88-88C344 151.4 304.6 112 256 112zM256 240c-22.06 0-40-17.95-40-40C216 177.9 233.9 160 256 160s40 17.94 40 40C296 222.1 278.1 240 256 240zM256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-46.73 0-89.76-15.68-124.5-41.79C148.8 389 182.4 368 220.2 368h71.69c37.75 0 71.31 21.01 88.68 54.21C345.8 448.3 302.7 464 256 464zM416.2 388.5C389.2 346.3 343.2 320 291.8 320H220.2c-51.36 0-97.35 26.25-124.4 68.48C65.96 352.5 48 306.3 48 256c0-114.7 93.31-208 208-208s208 93.31 208 208C464 306.3 446 352.5 416.2 388.5z"
                            ></path>
                          </svg>
                          <span>
                            Welcome , {localStorage.getItem("username")} ! (
                            <small> <i className="icon icon-power"></i> </small> )
                          </span>
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Header */}
        <div className="sticky">
          <div className="horizontal-header clearfix ">
            <div className="container">
              <Link to="/home" className="animated-arrow">
                <span />
              </Link>
              <span className="smllogo">
                <img
                  src="../assets/images/brand/logo.png"
                  width={120}
                  className="logo-img"
                />
                <img
                  src="../assets/images/brand/logo1.png"
                  width={120}
                  className="logo-img1"
                />
              </span>
              <a href="tel:245-6325-3256" className="callusbtn">
                <i className="fa fa-phone" aria-hidden="true" />
              </a>
            </div>
          </div>
        </div>
        {/* /Mobile Header */}
        {/* Horizontal-navigation */}
        <div className="header-style header-style1 horizontal-main clearfix">
          <div className="horizontal-mainwrapper container clearfix d-flex">
            <div className="clearfix text-center">
              <div className="header-search-logo d-none d-lg-block justify-content-center">
                <div className="header-logo">
                  <Link to="/">
                    <img src={logo} className="logo-img1 d-block" />
                  </Link>
                </div>
              </div>
            </div>
            {/*Nav*/}
            <div className="ms-auto">
              <nav className="horizontalMenu clearfix d-md-flex align-items-center justify-content-center">
                <ul className="horizontalMenu-list rightside-btns justify-content-center">
                  <li aria-haspopup="true">
                    <Link to="/">
                      <i className="fa fa-home menu-icon" />
                      Home
                      <i className="fe fe-chevron-down iconangle ms-1 m-0" />
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link to="/blogs">
                      <i className="fa fa-address-book menu-icon" />
                      Blogs
                    </Link>
                  </li>

                  {showDash ? (
                    <li aria-haspopup="true">
                      <Link to="/user-dashboard">
                        <i className="fa fa-desktop menu-icon" />
                        Dashboard
                      </Link>
                    </li>
                  ) : (
                    <li aria-haspopup="true">
                      <Link to="#">
                        <i className="fa fa-desktop menu-icon" />
                        Dashboard
                      </Link>
                    </li>
                  )}

                  <li aria-haspopup="true">
                    <Link to="/products">
                      <i className="fa fa-product-hunt menu-icon" />
                      Products
                    </Link>
                  </li>
                  <li aria-haspopup="true">
                    <Link to="/contact-us">
                      <i className="fa fa-address-book-o menu-icon" />
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            {/*Nav*/}
          </div>
        </div>
        {/* /Horizontal-navigation */}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
}

export default Header;
