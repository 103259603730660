import React, { useState, useEffect } from "react";
import APIService from "../Data/APIService";
import config from "../../config";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./testimonials.css"

function Testimonials() {
  const [msgs, setMsgs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    APIService.Testimonials()
      .then((resp) => {
        setMsgs(resp);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching testimonials:", error);
        setLoading(false);
      });
  }, []);

  const options = {
    loop: true,
    rewind: false,
    margin: 25,
    animateIn: "fadeInDown",
    animateOut: "fadeOutDown",
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    dots: false,
    nav: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      600: {
        items: 1,
        nav: true,
      },
      1300: {
        items: 1,
        nav: true,
      },
    },
  };

  return (
    <div>
      <section className="sptb bg-white">
        <div className="container">
          <div className="section-title center-block text-center">
            <h2>Testimonials</h2>
            <p>Mauris ut cursus nunc. Morbi eleifend, ligula at consectetur vehicula</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              {loading ? (
                <p>Loading...</p>
              ) : msgs && msgs.length > 0 ? (
                <OwlCarousel className="owl-carousel testimonial-owl-carousel" {...options}>
                  {msgs.map((item, index) => (
                    <div className="item text-center" key={index}>
                      <div className="row">
                        <div className="col-xl-8 col-md-12 d-block mx-auto">
                          <div className="testimonia">
                            <div className="testimonia-img mx-auto mb-3">
                              <img
                                src={`${config.apiUrl}${item.profile_picture_path}`}
                                className="img-thumbnail rounded-circle alt="
                                style={{ maxHeight: "100px", maxWidth: "100px" }}
                                alt=""
                              />
                            </div>
                            <p>
                              <i className="fa fa-quote-left" /> {item.message}
                            </p>
                            <div className="testimonia-data">
                              <h4>{item.user_name}</h4>
                              <div className="d-inlin e-flex flex-wrap">
                                <div className="rating-star sm my-rating-5" data-stars="4s"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              ) : (
                <p>No testimonials available</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;