import React, { useState } from 'react';
import MyModal from "../component/dashboard/MyModel" // Import your modal component

const TestModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h1>My App</h1>
      <button onClick={openModal}>Open Modal</button>
      <MyModal show={showModal} onHide={closeModal} />
    </div>
  );
};

export default TestModal;