import React, { useEffect, useState } from "react";
import APISerive from "../Data/APIService";
import config from "../../config";
import CommentFormApp from "../comment/CommentFormApp";
import FeedbacksApp from "../comment/FeedbacksApp";
import { Link } from "react-router-dom";
import StarRating from "../starRating/Rating";
import $ from 'jquery';
function AppDetail(props) {
  const [app, setApp] = useState([]);
  const [feedbacks, setFeedbacks] = useState(null);
  const [rating, setRating] = useState(0);

  const reloadFeedbacks = (newFeedbacks) => {
    setFeedbacks(newFeedbacks);
  };

  useEffect(() => {
    const fetchData = async () => {
      $("#global-loader").fadeOut("slow");
      try {
        const user_token = localStorage.getItem("token");
        // Fetch article data
        const res = await APISerive.getApp(props.idApp);
        setApp(res);

        // feedback
        // Fetch feedback data
        const feedbackData = await APISerive.getFeedback_app(props.idApp);

        setFeedbacks(feedbackData);

        // sasve visit data

        if (props.idApp != null) {
          //get rating
          const ratingResponse = await APISerive.Articles_get_app_rating(props.idApp);
          setRating(Number(ratingResponse.average_rating));
          //
          await APISerive.Apps_Save_visited({
            user: localStorage.getItem("user") || 0,
            app: props.idApp,
          }).catch((res) => console.log("error occured during save visited"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);


  return (
    <div>
      {/*Breadcrumb*/}
      <div className="bg-white border-bottom mt-2">
        <div className="container">
          <div className="page-header">
            <h4 className="page-title">Software</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="/products">Products</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                App details
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/*Add listing*/}
      <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              {/*App Description*/}
              <div className="card overflow-hidden">
                <div className="ribbon ribbon-top-right text-danger">
                  <span className="bg-danger">{app.category_name}</span>
                </div>
                <div className="card-body  item-user">
                  <div className="profile-pic mb-0">
                    <div className="d-md-flex">
                      <img
                        src={`${config.apiUrl}${app.image_url}`}
                        className="w-150 h-150 br-2"
                        alt="user"
                      />
                      <div className="ms-4">
                        <a href="javascript:void(0);" className="text-dark">
                          <h4 className="mt-1 mb-1 font-weight-bold">
                            {app.title}
                            <small className="text-muted text-small fs-13 ms-2 d-inline-block">
                              {app.intro}
                            </small>
                          </h4>
                        </a>
                        <p className="mb-0">At vero eos et accusamus</p>
                        <span className="text-muted">version: 1.6</span>
                        <br />
                        <div className="d-inline-flex flex-wrap">
                        
                        <StarRating value={app.average_rating} />
                        ({app.average_rating}/5)
                        </div>
                        <h6 className="mt-2 mb-0">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-green my-1"
                          >
                            {app.license}
                          </a>{" "}
                          <a
                            href={app.applink}
                            className="btn btn-warning my-1"
                          >
                            <i className="fe fe-paperclip me-1" />
                            {"go to app >>"}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Details Description*/}

              {/*Add Description*/}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Description</h3>
                </div>
                <div className="card-body">
                  <div className="mb-4">
                    <p>{app.description}</p>
                  </div>
                </div>
              </div>
              {/*/Add Description*/}
              {/*Pros cons*/}
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Pros and cons</h3>
                </div>
                <div className="card-body">
                  <p>{app.posandcosn}</p>
                </div>
              </div>
              {/*/Pros cons*/}

              {app ? (
                <>
                  {feedbacks ? (
                    <FeedbacksApp feedbacks={feedbacks} reloadFeedbacks={reloadFeedbacks} />
                  ) : (
                    <p>No Feedbaks.</p>
                  )}
                  <CommentFormApp app={app} reloadFeedbacks={reloadFeedbacks} />
                </>
              ) : (
                <p>No comments...</p>
              )}
            </div>
            {/*/leave a comment*/}

            
          </div>
        </div>
      </section>
      {/*/Add listing*/}

      {/* Newsletter*/}
      <section className="sptb bg-white border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-md-12">
              <div className="sub-newsletter">
                <h3 className="mb-2">
                  <i className="fa fa-paper-plane-o me-2" /> Subscribe To Our
                  Newsletter
                </h3>
                <p className="mb-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-xl-6 col-md-12">
              <div className="input-group sub-input mt-1">
                <input
                  type="text"
                  className="form-control input-lg "
                  placeholder="Enter your Email"
                />
                <button
                  type="button"
                  className="btn btn-primary btn-lg br-te-3 br-be-3"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/Newsletter*/}
    </div>
  );
}

export default AppDetail;
