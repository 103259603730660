import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ArticlePost from "../Post/ArticlePost";
import DOMPurify from "dompurify";
import config from "../../config";
import APISerive from "../Data/APIService";
import $ from 'jquery';
function sanitizeAndRenderHTML(htmlContent) {
  const cleanHTML = DOMPurify.sanitize(htmlContent);
  return { __html: cleanHTML };
}

function BlogList(props) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [pageUrl, setpageUrl] = useState();

  const paginationSetUrl = (url) => {
    setpageUrl(url);
  };
  const handleCheckboxChange = (event, categoryId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((id) => id !== categoryId)
      );
    }
  };

  const fetchArticlesByCategories = async () => {
    try {
      $("#global-loader").fadeIn("slow");
      if (selectedCategories.length === 0) {
        APISerive.Articles_published().then((resp) => {
          //setAppsudated(resp);
          props.reloadBlogs(resp.results);
        });
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append("category_ids", selectedCategories);
        APISerive.Articles_published_byCategories(selectedCategories).then(
          (resp) => {
            //setAppsudated(resp);
            props.reloadBlogs(resp.results);
          }
        );
      }
    } catch (error) {
      console.error("Error fetching apps:", error);
    }finally{
      $("#global-loader").fadeOut("slow");
    }
  };

  const paginationHandler = async (url) => {
    try {
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.results) {
        props.reloadNextUrl(data.next);
        props.reloadPreviousUrl(data.previous);
        console.log("datafrom paginaf")
        console.log(data.results)
        props.reloadBlogs(data.results);
      } else {
        console.error("Invalid response format:", data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchArticlesByCategories();
  }, [selectedCategories]);

  useEffect(() => {
    paginationHandler(pageUrl);
  }, [pageUrl]);
  /*   const handlePostClick = () => {
    setShowPost(true);
    setEditArticle({title:'', description:''})
  }; */
  const articles = Object.values(props.articles);

  const myProps = {
    prop1: props.articles,
    prop2: props.category,
  };

  return (
    <section className="sptb">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-12">
            {/*Coursed lists*/}
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12"></div>
              {Object.values(props.articles).map((article) => (
                <div key={article.id} className="col-xl-12 col-lg-12 col-md-12">
                  <div className="card overflow-hidden">
                    <div className="ribbon ribbon-top-left">
                      <span className="bg-danger">featured </span>
                    </div>
                    <div className="row g-0 blog-list">
                      <div className="col-xl-4 col-lg-12 col-md-12 pe-0">
                        <div className="item7-card-img br-be-0 br-te-0">
                          <a href="app-details.html" />
                          <img
                            src={`${config.apiUrl}${article.image_url}`}
                            alt={article.title}
                            className="cover-image"
                          />
                          <div className="item7-card-text">
                            <a
                              className="item-card-badge bg-primary text-white"
                              data-bs-toggle="tooltip"
                              data-bs-original-title="Business"
                            >
                              <i className="fa fa-briefcase" />{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-lg-12 col-md-12 ps-0">
                        <div className="card-body pb-4 pt-4">
                          <div className="mb-3">
                            {/* <Link to={{pathname:`/blogs/blog-detail/${article.id}/`,state:{customProp:"hello"}}}> */}
                            <Link to={`/blog/${article.slug}`}>
                              <h3 className="font-weight-semibold fs-16 mb-3">
                                {article.title}
                              </h3>
                            </Link>

                            <div
                              dangerouslySetInnerHTML={sanitizeAndRenderHTML(
                                article.intro
                              )}
                            />
                          </div>
                          <div className="d-flex align-items-center mt-auto">
                            <img
                              src="../assets/images/users/female/1.jpg"
                              className="avatar brround avatar-md me-3"
                              alt="avatar-img"
                            />
                            <div>
                              <a
                                href="profile.html"
                                className="text-default font-weight-bold"
                              >
                                {article.user_name}
                              </a>
                              <small className="d-block text-muted">
                              {(() => {
                                const postDate = new Date(article.pub_date);
                                const currentDate = new Date();
                                const timeDifference = currentDate.getTime() - postDate.getTime();
                          
                                const seconds = Math.floor(timeDifference / 1000);
                                const minutes = Math.floor(seconds / 60);
                                const hours = Math.floor(minutes / 60);
                                const days = Math.floor(hours / 24);
                          
                                if (days > 0) {
                                  return `${days} day${days > 1 ? 's' : ''} ago`;
                                } else if (hours > 0) {
                                  return `${hours} hour${hours > 1 ? 's' : ''} ago`;
                                } else if (minutes > 0) {
                                  return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
                                } else {
                                  return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
                                }
                              })()}
                            </small>
                            </div>
                            <div className="ms-auto text-muted">
                              <a href="javascript:void(0);">
                                <i className="fa fa-comment-o me-2" />
                                {article.comment_count} comments
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="center-block text-center">
              <ul className="pagination mb-5 mb-lg-0">
                {props.previousUrl && (
                  <li className="page-item page-prev ">
                    <button
                      className="page-link"
                      onClick={() => paginationSetUrl(props.previousUrl)}
                      tabIndex={-1}
                    >
                      prev
                    </button>
                  </li>
                )}
                {props.nextUrl && (
                  <li className="page-item page-next">
                    <button
                      className="page-link"
                      onClick={() => paginationSetUrl(props.nextUrl)}
                    >
                      Next
                    </button>
                  </li>
                )}
              </ul>
            </div>
            {/*/Coursed lists*/}
          </div>
          {/*Right Side Content*/}
          <div className="col-xl-4 col-lg-4 col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <button type="button" className="btn btn-primary">
                    {" "}
                    Search{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Categories</h3>
              </div>
              <div className="card-body">
                <div id="container">
                  <div className="filter-product-checkboxs align-item-center">
                    {props.category &&
                      props.category.map((category) => {
                        return (
                          <label
                            className="custom-control custom-checkbox mb-3"
                            key={category.id}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="checkbox1"
                              value={category.id}
                              onChange={(event) =>
                                handleCheckboxChange(event, category.id)
                              }
                              checked={selectedCategories.includes(category.id)}
                            />
                            <span className="custom-control-label" style={{lineHeight:'0.5rem'}} >
                              <span>
                                {category.name}
                                <span className="label label-secondary float-end">
                                  {category.post_count}
                                </span>
                              </span>
                            </span>
                          </label>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Latest Posts</h3>
              </div>
              <div className="card-body p-3">
                <div className="list-catergory1">
                  <div className="item-list">
                    <ul className="list-group mb-0">
                      {Object.values(props.recArticles).map(
                        (recArticlesGroup) => {
                          return recArticlesGroup.map((Rarticle) => (
                            <li className="list-group-item d-flex border-bottom-0">
                              <img
                                src={`${config.apiUrl}${Rarticle.image_url}`}
                                className="avatar br-5 avatar-xl me-3 my-auto"
                                alt="avatar-img"
                              />
                              <div>
                                <small className="d-block">
                                  {Rarticle.category["name"]}
                                </small>
                                <a className="text-default font-weight-semibold">
                                  {Rarticle.title}
                                </a>
                                <small className="d-block text-muted">
                                  1 day ago
                                </small>
                              </div>
                            </li>
                          ));
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*/Right Side Content*/}
        </div>
      </div>
    </section>
  );
}

export default BlogList;
