import React from 'react'

function blogDetail() {
  return (
   <section className="sptb">
  <div className="container">
    <div className="row">
      <div className="col-xl-8 col-lg-8 col-md-12">
        <div className="card blog-detail">
          <div className="card-body">
            <div className="item7-card-img br-7 mb-5">
              <img src="../assets/images/photos/12.jpg" alt="img" className="w-100" />
              <div className="item-card-text-bottom me-0">
                <h4 className="mb-0">Business</h4>
              </div>
            </div>
            <a href="javascript:void(0);" className="text-dark"><h2 className="font-weight-semibold">Business Management</h2></a>
            <div className="item7-card-desc d-md-flex mb-2 mt-3">
              <a href="javascript:void(0);" className="font-weight-semibold fs-15 d-inline-block"><i className="fe fe-calendar me-2 text-primary" />Nov-28-2018</a>
              <a href="javascript:void(0);" className="font-weight-semibold fs-15 d-inline-block"><i className="fe fe-user me-2 text-primary" />Starley Helen</a>
              <div className="ms-auto">
                <a href="javascript:void(0);" className="font-weight-semibold fs-15"><i className="fe fe-message-circle me-2 text-primary" />2 Comments</a>
              </div>
            </div>
            <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful. </p>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.</p>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Comments</h3>
          </div>
          <div className="card-body">
            <div className="media mt-0 p-5 border br-7 review-media">
              <div className="d-flex me-3">
                <a href="javascript:void(0);"><img className="media-object brround avatar-lg" alt="64x64" src="../assets/images/users/male/13.jpg" /> </a>
              </div>
              <div className="media-body">
                <div className="mt-0 mb-1 font-weight-semibold fs-15">Joanne Scott
                  <div className="fs-14 float-md-end d-block d-md-flex">
                    <div className="rating-star sm my-rating-5" data-stars="4s">
                    </div>
                  </div>
                </div>
                <small className="text-muted fs-13">
                  <i className="fa fa-clock-o" />  Reviewed 2 Hours Ago
                </small>
                <p className="font-13 mb-2 mt-2">
                  Ut enim ad minim veniam, quis Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et  nostrud exercitation ullamco laboris   commodo consequat.
                </p>
                <div className="d-md-flex">
                  <a href="javascript:void(0);" className="me-2 text-primary mt-1"><i className="fa fa-mail-reply me-2" />Reply</a>
                  <div className="d-md-flex ms-auto my-auto">
                    <a href="javascript:void(0);" className="me-2 mt-1">Recommended ?</a>
                    <a href="javascript:void(0);" className="me-2"><span className="btn btn-success btn-sm"><i className="fa fa-thumbs-o-up me-2" />Yes</span></a>
                    <a href="javascript:void(0);" className="me-2"><span className="btn btn-danger btn-sm"><i className="fa fa-thumbs-o-down me-2" />No</span></a>
                  </div>
                </div>
                <div className="media mt-5">
                  <div className="d-flex me-3">
                    <a href="javascript:void(0);"> <img className="media-object brround" alt="64x64" src="../assets/images/users/female/1.jpg" /> </a>
                  </div>
                  <div className="media-body">
                    <h4 className="mt-0 mb-1 font-weight-semibold fs-15">Rose Slater </h4>
                    <small className="text-muted fs-13">
                      <i className="fa fa-clock-o" />  Reviewed 1 Hours Ago
                    </small>
                    <p className="font-13 mb-2 mt-2">
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris   commodo Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium  laboriosam, nisi ut aliquid ex ea commodi consequatur consequat.
                    </p>
                    <a href="javascript:void(0);" className="me-2 text-primary"><i className="fa fa-mail-reply me-2" />Reply</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="media  mt-2 p-5 border br-7">
              <div className="d-flex me-3">
                <a href="javascript:void(0);"><img className="media-object brround avatar-lg" alt="64x64" src="../assets/images/users/male/8.jpg" /> </a>
              </div>
              <div className="media-body">
                <div className="mt-0 mb-1 font-weight-semibold fs-15">Edward
                  <div className="fs-14 float-md-end d-block d-md-flex">
                    <div className="rating-star sm my-rating-5" data-stars="4s">
                    </div>
                  </div>
                </div>
                <small className="text-muted fs-13">
                  <i className="fa fa-clock-o" />  Reviewed 2 Hours Ago
                </small>
                <p className="font-13 mb-2 mt-2">
                  Ut enim ad minim veniam, quis Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et  nostrud exercitation ullamco laboris   commodo consequat.
                </p>
                <div className="d-md-flex">
                  <a href="javascript:void(0);" className="me-2 text-primary mt-1"><i className="fa fa-mail-reply me-2" />Reply</a>
                  <div className="d-md-flex ms-auto my-auto">
                    <a href="javascript:void(0);" className="me-2 mt-1">Recommended ?</a>
                    <a href="javascript:void(0);" className="me-2"><span className="btn btn-success btn-sm"><i className="fa fa-thumbs-o-up me-2" />Yes</span></a>
                    <a href="javascript:void(0);" className="me-2"><span className="btn btn-danger btn-sm"><i className="fa fa-thumbs-o-down me-2" />No</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-lg-0">
          <div className="card-header">
            <h3 className="card-title">Write Your Comments</h3>
          </div>
          <div className="card-body">
            <div className="form-group">
              <input type="text" className="form-control" id="name1" placeholder="Your Name" />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" id="email" placeholder="Email Course address" />
            </div>
            <div className="form-group">
              <textarea className="form-control" name="example-textarea-input" rows={4} placeholder="Write Your Comment" defaultValue={""} />
            </div>
            <a href="javascript:void(0);" className="btn btn-primary">Submit</a>
          </div>
        </div>
      </div>
      {/*Rightside Content*/}
      <div className="col-xl-4 col-lg-4 col-md-12">
        <div className="card">
          <div className="card-body">
            <div className="input-group">
              <input type="text" className="form-control" placeholder="Search" />
              <button type="button" className="btn btn-primary"> Search </button>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Blog Categories</h3>
          </div>
          <div className="card-body p-2">
            <div className="list-catergory">
              <div className="item-list">
                <ul className="list-group mb-0">
                  <li className="list-group-item">
                    <a href="javascript:void(0);" className="text-default-dark">
                      <i className="fe fe-chevrons-right" /> Business
                      <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">14 Posts</span>
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="javascript:void(0);" className="text-default-dark">
                      <i className="fe fe-chevrons-right" /> IT Services
                      <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">74 Posts</span>
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="javascript:void(0);" className="text-default-dark">
                      <i className="fe fe-chevrons-right" /> Research
                      <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">18 Posts</span>
                    </a>
                  </li>
                  <li className="list-group-item">
                    <a href="javascript:void(0);" className="text-default-dark">
                      <i className="fe fe-chevrons-right" /> Mobile computing
                      <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">32 Posts</span>
                    </a>
                  </li>
                  <li className="list-group-item border-bottom-0 br-bs-7 br-be-7">
                    <a href="javascript:void(0);" className="text-default-dark">
                      <i className="fe fe-chevrons-right" /> Listing Applications
                      <span className="badgetext badge badge-pill mb-0 mt-1 text-muted font-weight-normal bg-default-transparent">08 Posts</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Latest Posts</h3>
          </div>
          <div className="card-body p-3">
            <div className="list-catergory1">
              <div className="item-list">
                <ul className="list-group mb-0">
                  <li className="list-group-item d-flex border-bottom-0">
                    <img src="../assets/images/photos/11.jpg" className="avatar br-5 avatar-xl me-3 my-auto" alt="avatar-img" />
                    <div className>
                      <small className="d-block">Business</small>
                      <a className="text-default font-weight-semibold">Excepteur occaecat cupidatat</a>
                      <small className="d-block text-muted">1 day ago</small>
                    </div>
                  </li>
                  <li className="list-group-item d-flex border-bottom-0">
                    <img src="../assets/images/photos/12.jpg" className="avatar br-5 avatar-xl me-3 my-auto" alt="avatar-img" />
                    <div className>
                      <small className="d-block">Market Place</small>
                      <a className="text-default font-weight-semibold">Excepteur occaecat cupidatat</a>
                      <small className="d-block text-muted">1 day ago</small>
                    </div>
                  </li>
                  <li className="list-group-item d-flex border-bottom-0">
                    <img src="../assets/images/photos/13.jpg" className="avatar br-5 avatar-xl me-3 my-auto" alt="avatar-img" />
                    <div className>
                      <small className="d-block">Mobile Computing</small>
                      <a className="text-default font-weight-semibold">Excepteur occaecat cupidatat</a>
                      <small className="d-block text-muted">1 day ago</small>
                    </div>
                  </li>
                  <li className="list-group-item d-flex border-bottom-0">
                    <img src="../assets/images/photos/14.jpg" className="avatar br-5 avatar-xl me-3 my-auto" alt="avatar-img" />
                    <div className>
                      <small className="d-block">App Developing</small>
                      <a className="text-default font-weight-semibold">Excepteur occaecat cupidatat</a>
                      <small className="d-block text-muted">1 day ago</small>
                    </div>
                  </li>
                  <li className="list-group-item d-flex border-bottom-0 br-bs-7 br-be-7">
                    <img src="../assets/images/photos/15.jpg" className="avatar br-5 avatar-xl me-3 my-auto" alt="avatar-img" />
                    <div className>
                      <small className="d-block">Networking</small>
                      <a className="text-default font-weight-semibold">Excepteur occaecat cupidatat</a>
                      <small className="d-block text-muted">1 day ago</small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Popular Classes</h3>
          </div>
          <div className="card-body">
            <div className="product-tags clearfix">
              <ul className="list-unstyled mb-0">
                <li><a href="javascript:void(0);">Research</a></li>
                <li><a href="javascript:void(0);">Measuring and Monitoring</a></li>
                <li><a href="javascript:void(0);">Marketing</a></li>
                <li><a href="javascript:void(0);">Prioritize</a></li>
                <li><a href="javascript:void(0);">Market Place</a></li>
                <li><a href="javascript:void(0);">Digital marketing</a></li>
                <li><a href="javascript:void(0);">Listing</a></li>
                <li><a href="javascript:void(0);">Applications</a></li>
                <li><a href="javascript:void(0);">Market Intelligence</a></li>
                <li><a href="javascript:void(0);" className="mb-0">Mobile Action</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Blog Authors</h3>
          </div>
          <div className="card-body p-0">
            <ul className="vertical-scroll authors">
              <li className="item2">
                <div className="d-flex mt-0 mb-0">
                  <div className="d-flex footerimg-l mb-0">
                    <img src="../assets/images/users/female/12.jpg" alt="image" className="avatar avatar-md brround  me-2" />
                    <div className="my-auto">
                      <a href="javascript:void(0);" className="text-dark font-weight-semibold">Jacobin</a>
                      <p className="text-muted mb-0 fs-13">Research</p>
                    </div>
                  </div>
                  <div className="mt-2 footerimg-r ms-auto">
                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="Likes"><span className="badge bg-primary-transparent text-primary"><i className="fe fe-heart" /> 36</span></a>
                  </div>
                </div>
              </li>
              <li className="item2">
                <div className="d-flex mt-0 mb-0">
                  <div className="d-flex footerimg-l mb-0">
                    <img src="../assets/images/users/female/7.jpg" alt="image" className="avatar avatar-md brround  me-2" />
                    <div className="my-auto">
                      <a href="javascript:void(0);" className="text-dark font-weight-semibold">Julia Hardacre</a>
                      <p className="text-muted mb-0 fs-13">WebDevelopment</p>
                    </div>
                  </div>
                  <div className="mt-2 footerimg-r ms-auto">
                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="Likes"><span className="badge bg-primary-transparent text-primary"><i className="fe fe-heart" /> 36</span></a>
                  </div>
                </div>
              </li>
              <li className="item2">
                <div className="d-flex mt-0 mb-0">
                  <div className="d-flex footerimg-l mb-0">
                    <img src="../assets/images/users/male/11.jpg" alt="image" className="avatar avatar-md brround  me-2" />
                    <div className="my-auto">
                      <a href="javascript:void(0);" className="text-dark font-weight-semibold">Dewitt Hennessey</a>
                      <p className="text-muted mb-0 fs-13">3D Animation</p>
                    </div>
                  </div>
                  <div className="mt-2 footerimg-r ms-auto">
                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="Likes"><span className="badge bg-primary-transparent text-primary"><i className="fe fe-heart" /> 36</span></a>
                  </div>
                </div>
              </li>
              <li className="item2">
                <div className="d-flex mt-0 mb-0">
                  <div className="d-flex footerimg-l mb-0">
                    <img src="../assets/images/users/female/14.jpg" alt="image" className="avatar avatar-md brround  me-2" />
                    <div className="my-auto">
                      <a href="javascript:void(0);" className="text-dark font-weight-semibold">Barbra Flegle </a>
                      <p className="text-muted mb-0 fs-13">App Developer</p>
                    </div>
                  </div>
                  <div className="mt-2 footerimg-r ms-auto">
                    <a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top" title data-bs-original-title="Likes"><span className="badge bg-primary-transparent text-primary"><i className="fe fe-heart" /> 36</span></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/*/Rightside Content*/}
    </div>
  </div>
</section>

  )
}

export default blogDetail
